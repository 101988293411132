import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SignatureService } from "../../../services/SignatureService";

export default function CapchaComponent({ setStateCaptcha, setBlocked }) {

  const catpchaChange = async (e) => {
    setStateCaptcha(true);
    setBlocked(false);
    try {
      const validateCAPTCHA = SignatureService.getInstance();
      const resultCaptcha = await validateCAPTCHA.validateCAPTCHA({
        Body: e
      });
      console.log("resultCaptcha.Body.Success//**/*/-/-*/-*/-*/-*: ",resultCaptcha.Body.Success)
      if (resultCaptcha.Body.Success) {
        setStateCaptcha(false);
        setBlocked(false);
      } else {
        setStateCaptcha(false);
        setBlocked(true);
      }
    } catch (error) {
      console.log('errorCapcha',error)
      setStateCaptcha(false);
      setBlocked(true);
    }

  };

  return (
    <div>
      <ReCAPTCHA
        style={{ width: '100%', display:"flex", textAlign:"center" }}
        className="iframeCaptcha"
        sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
        onChange={catpchaChange}
      />
    </div>
  );
}
