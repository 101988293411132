import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

import {
  getFiles,
  getFile,
  getGlobalFiles,
  setFiles,
  editStack,
  resetFile,
  resetNewFile,
  resetDocumentEnlace,
  resetDocument64,
} from "../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { constUrlGraficas,constUrlGraficasMovil, useWindowDimensions } from "../../utils";

export const Dashboards = (props) => {
  const { height, width } = useWindowDimensions();
  const matches = (width <= 600);
  const [urlCharts, setUrlCharts] = useState(null);

  useEffect(() => {
    // console.log("matches: ",width,matches)
     var urlGenerate = matches ?`${constUrlGraficasMovil.ambient}?reference=${"aa"}&token=${sessionStorage.token}`
    :`${constUrlGraficas.ambient}?reference=${"aa"}&token=${sessionStorage.token}`;
    console.log("url: ",urlGenerate)
    setUrlCharts(urlGenerate)
  }, []);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      {urlCharts ?
        <object data={urlCharts} width="100%" height={height - 150}></object>
        : null}
    </LocalizationProvider>
  );
};