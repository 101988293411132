import { Box, Button, Grid, Typography } from "@mui/material";
import logoDoc from "../../assets/SoluSignLocoNegro.png";
import fondoQR from "./assets/FondoQR.png";
// import { useNavigate } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { QrReader } from "react-qr-reader";
import "./stylesCode.css";
import BackdropComponent from "../../components/BackDrop";
import styled from "@emotion/styled";

const Image = styled("img")(({ theme }) => ({
  width: "30%",
  height: "auto",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    width: "100vh",
  },
  [theme.breakpoints.down("xs")]: {
    height: "100%",
    width: "100vh",
  },
}));

const ImageFondo = styled("img")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "auto",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    width: "100vh",
  },
  [theme.breakpoints.down("xs")]: {
    height: "100%",
    width: "100vh",
  },
}));

export default function CodeReader() {
  const [qrStatus, setQrStatus] = useState(false);
  // const [data, setData] = useState("No result");
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [legacyMode, setLegacyMode] = useState(false);

  const handleError = (err) => {
    console.log(err);
  };

  const scaner = (result, error) => {
    if (!!result) {
      setLoading(true);
      if (result?.text) {
        setLoading(true);
        window.location.href = `${result?.text}`;
      }
    }
    if (!!error) {
    }
  };

  const openImageDialog = () => {
    this.ref.qrReader1.openImageDialog();
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid container justifyContent={"center"} justifyItems="center">
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center", p: 3 }}>
            <Typography sx={{ fontWeight: 600, mt: 3 }} variant="subtitle1">
              Bienvenido a
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  p: 1,
                  // width: "70%",
                }}
              >
                <Image src={logoDoc} alt="Logo" />
              </Box>
            </Box>
            <Typography sx={{ fontWeight: 400, mt: 2 }} variant="subtitle1">
              Escanea el código QR que llegó a tu correo electrónico y empieza a
              firmar
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {!qrStatus ? (
        <div className="contenedor">
          <div className="contenedorDos">
            <div className="contenedorFoto">
              <ImageFondo src={fondoQR} alt="Logo" />
            </div>
          </div>
          <Box sx={{display:{xs: 'block', md:'none'}}}>
            <div className="contenedorBoton">
              <Button
                variant="outlined"
                color="primary"
                sx={{ maxWidth: 150 }}
                onClick={() => setQrStatus(!qrStatus)}
              >
                Continuar
              </Button>
            </div>
          </Box>
        </div>
      ) : (
        <>
          <QrReader
            style={{ minHeight: 240, minWidth: 320 }}
            onError={handleError}
            constraints={{ facingMode: "environment" }}
            onResult={(result, error) => scaner(result, error)}
          />
          {legacyMode ? (
            <input
              type="button"
              value="Adjuntar QR"
              onClick={() => openImageDialog()}
            />
          ) : (
            ""
          )}
          <Grid justifyContent={"center"} item xs={12} md={6}>
            <Box
              sx={{
                p: 2,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: "80%" }}
                onClick={() => setQrStatus(!qrStatus)}
              >
                Cancelar
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Fragment>
  );
}
