import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  tooltipClasses,
  Tooltip,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import aprobador from "../../assets/icons/Aprobador.png";
import subir from "../../assets/icons/Cargador.png";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import BackdropComponent from "../../components/BackDrop";
import { makeStyles } from "@mui/styles";
import { UTCtoLocal } from "../../utils/Tools";
import { ToastNotification } from "../../components/toastNotification";
import { FileFlowService } from "../../services/FileFlowService";
import { useSelector } from "react-redux";
import { Circle } from "@mui/icons-material";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 5,
  marginBottom: 1,
  borderBottom: "1px solid #D9D9D9",
  display: "flex",
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "60%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
    "& .MuiTableCell-root": {
      border: `.9px solid ${theme.palette.primary.main20}`,
    },
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 500,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const headCells = [
  {
    id: "FullName",
    numeric: false,
    disablePadding: true,
    label: "Nombre del participante",
  },
  {
    id: "Function",
    numeric: true,
    disablePadding: false,
    label: "Función",
  },
  {
    id: "SignDeadlineFormat",
    numeric: true,
    disablePadding: false,
    label: "Fecha límite",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Estatus",
  },
  {
    id: "Correo Electronico",
    numeric: true,
    disablePadding: false,
    label: "Reenvío de Correo Electrónico",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell1 colSpan={5} align={"center"}>
          <Typography variant="subtitle" sx={{ fontWeight: 700 }}>
            <b>Requisitos previos</b>
          </Typography>
        </StyledTableCell1>
      </TableRow>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell1
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
          >
            <b>{headCell.label}</b>
          </StyledTableCell1>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ParticipantSortTablePrerequites(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const fileState = useSelector((state) => state.File);
  const userLogin = useSelector((state) => state.Authentication?.items);
  const newFileStage = useSelector((state) => state.File?.item?.FileData);
  const [listDocuments, setListDocuments] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [loading, setLoading] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("calories");

  useEffect(() => {
    if (newFileStage.Stage !== undefined && newFileStage.Stage !== null) {
      var listDocValidate =
        newFileStage.Actors[0]?.DocumentalGroups[0]?.Documents;
      var listDocStatus =
        newFileStage.Stage?.Actor?.DocumentalGroups[0]?.DocumentTypes[0]
          ?.Documents;
      var listProp = newFileStage.Stage?.Properties?.find(
        (item) => item.Name === "DocumentListName"
      )?.Value;
      if (listProp !== undefined && listProp !== null && listProp !== "") {
        var listProperties = JSON.parse(listProp);

        let newData = listProperties?.map((data) => {
          var statusValue = listDocStatus.find(
            (item) => item.Name === data.DocumentName
          )?.Status;
          var statusValidate = listDocValidate?.find(
            (item) => item.Name === data.DocumentName
          )?.ValidationData?.Approved;

          return {
            Name: data.DocumentName,
            Required: data.Required,
            Status:
              statusValue !== undefined &&
              statusValue !== null &&
              statusValue !== ""
                ? statusValue
                : "Pendiente",
            Approved:
              statusValidate !== undefined &&
              statusValidate !== null &&
              statusValidate !== ""
                ? statusValidate
                : null,
          };
        });
        setListDocuments(newData);
      }
    }
  }, [newFileStage]);

  const statusDocsUpload =
    props.docsStackPrerre?.UploadPrerrequisitesStatusDocument?.filter(
      (item) => item?.Status !== "Cargado"
    ).length;

  const statusDocsUploadPendiente =
    props.docsStackPrerre?.ApprovalPrerrequisitesStatusDocument?.filter(
      (item) => item?.Status === "Pendiente"
    ).length;

  const statusDocsRechazado = listDocuments?.filter(
    (item) => item.Approved === false
  ).length;

  const statusDocsAproved =
    props.docsStackPrerre?.ApprovalPrerrequisitesStatusDocument?.filter(
      (item) => item?.Status !== "Validado"
    ).length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setRows(props.listRows);
  }, [props.listRows]);

  const validacion = () => {
    if (
      fileState?.item?.FileData.CreationUser === userLogin?.UserLoginData?.Email
    ) {
      if (
        fileState?.item?.FileData.StageName === "CargaPrerrequisitos" ||
        fileState?.item?.FileData.StageName === "ValidacionPrerrequisitos"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const sendMailUser = async (id) => {
    try {
      setLoading(true);
      const fileService = FileFlowService.getInstance();
      const responseSendMail = await fileService.SendMailNotification({
        Body: {
          file_Id: fileState?.item?.FileData?.Id,
          user_Id: id,
        },
      });
      if (responseSendMail.Body) {
        ToastNotification({
          icon: "success",
          text: responseSendMail?.Body?.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      ToastNotification({
        icon: "error",
        text: error?.message,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <BackdropComponent loading={loading} />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                type={props.type}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                <>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell1 align="left">
                      {props.docsStackPrerre?.UploadPrerequisitesUserFullName}
                    </StyledTableCell1>
                    <StyledTableCell1 align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Tip
                          title="Usuario a subir documentos."
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <img
                            style={{ width: 22 }}
                            alt="imagenLogo"
                            src={subir}
                          />
                        </Tip>
                      </Box>
                    </StyledTableCell1>
                    <StyledTableCell1 align="center">
                      {UTCtoLocal(
                        props.docsStackPrerre?.UploadPrerequisitesDeadline,
                        false
                      )}
                    </StyledTableCell1>
                    <StyledTableCell1 align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Circle
                          sx={{ mb: "-1px", mr: 1 }}
                          fontSize="small"
                          style={{
                            color:
                              statusDocsUploadPendiente !== 0
                                ? "#3E8F41"
                                : statusDocsRechazado !== 0
                                ? "#C5C8CD"
                                : statusDocsUpload === 0
                                ? "#3E8F41"
                                : "#C5C8CD",
                          }}
                        />
                        {statusDocsUploadPendiente !== 0
                          ? "Cargado"
                          : statusDocsRechazado !== 0
                          ? "Pendiente Carga"
                          : statusDocsUpload === 0
                          ? "Cargado"
                          : "Pendiente Carga"}
                      </Box>
                    </StyledTableCell1>
                    <StyledTableCell1 align="center">
                      <Tip
                        title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <spam>
                          <IconButton
                            onClick={() =>
                              sendMailUser(
                                props.docsStackPrerre?.UploadPrerequisitesUserId
                              )
                            }
                            disabled={validacion()}
                            sx={{ color: "#000000" }}
                          >
                            <ForwardToInboxIcon />
                          </IconButton>
                        </spam>
                      </Tip>
                    </StyledTableCell1>
                  </TableRow>
                  {props.docsStackPrerre.ActivePrerequisiteApproval === true ? (
                    <TableRow hover tabIndex={-1}>
                      <StyledTableCell1 align="left">
                        {props.docsStackPrerre
                          .PrerequisiteApprovalUserFullName ?? ""}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tip
                            title="Usuario Aprobador."
                            placement="top"
                            enterTouchDelay={0}
                          >
                            <img
                              style={{ width: 25 }}
                              alt="imagenLogo"
                              src={aprobador}
                            />
                          </Tip>
                        </Box>
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        {UTCtoLocal(
                          props.docsStackPrerre?.UploadPrerequisitesDeadline,
                          false
                        )}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Circle
                            sx={{ mb: "-1px", mr: 1 }}
                            fontSize="small"
                            style={{
                              color:
                                statusDocsUploadPendiente !== 0
                                  ? "#C5C8CD"
                                  : statusDocsRechazado !== 0
                                  ? "#DA2F2F"
                                  : statusDocsAproved === 0
                                  ? "#3E8F41"
                                  : statusDocsUpload === 0
                                  ? "#3E8F41"
                                  : "#C5C8CD",
                            }}
                          />
                          {statusDocsUploadPendiente !== 0
                            ? "Pendiente Validación"
                            : statusDocsRechazado !== 0
                            ? "Rechazado"
                            : statusDocsAproved === 0
                            ? "Aprobado"
                            : statusDocsUpload === 0
                            ? "Aprobado"
                            : "Pendiente Validación"}
                        </Box>
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        <Tip
                          title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <spam>
                            <IconButton
                              onClick={() =>
                                sendMailUser(
                                  props.docsStackPrerre
                                    ?.PrerequisiteApprovalUserId
                                )
                              }
                              disabled={validacion()}
                              sx={{ color: "#000000" }}
                            >
                              <ForwardToInboxIcon />
                            </IconButton>
                          </spam>
                        </Tip>
                      </StyledTableCell1>
                    </TableRow>
                  ) : null}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 1,
              m: 0.8,
              border: 1.5,
              borderColor: "#D9D9D9",
              borderRadius: 3,
            }}
          >
            <Box>
              <Typography variant="h6">Usuario a Subir Documentos</Typography>
            </Box>
            <Statements
              title={"Nombre del participante	"}
              text={props.docsStackPrerre?.UploadPrerequisitesUserFullName}
            />
            <Statements
              title={"Fecha límite"}
              text={UTCtoLocal(
                props.docsStackPrerre?.UploadPrerequisitesDeadline,
                false
              )}
            />
            <Statements
              title={"Estatus"}
              text={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Circle
                    sx={{ mb: "-1px", mr: 1 }}
                    fontSize="small"
                    style={{
                      color:
                        statusDocsUploadPendiente !== 0
                          ? "#3E8F41"
                          : statusDocsRechazado !== 0
                          ? "#C5C8CD"
                          : statusDocsUpload === 0
                          ? "#3E8F41"
                          : "#C5C8CD",
                    }}
                  />
                  {statusDocsUploadPendiente !== 0
                    ? "Cargado"
                    : statusDocsRechazado !== 0
                    ? "Pendiente Carga"
                    : statusDocsUpload === 0
                    ? "Cargado"
                    : "Pendiente Carga"}
                </Box>
              }
            />
            <Statements
              title={"Reenvío de Correo Electrónico"}
              text={
                <Tip
                  title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                  placement="top"
                  enterTouchDelay={0}
                >
                  <IconButton
                    onClick={() =>
                      sendMailUser(
                        props.docsStackPrerre?.UploadPrerequisitesUserId
                      )
                    }
                    disabled={validacion()}
                    sx={{ color: "#000000" }}
                  >
                    <ForwardToInboxIcon />
                  </IconButton>
                </Tip>
              }
            />
          </Box>
        </Grid>
        {props.docsStackPrerre.ActivePrerequisiteApproval === true ? (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 1,
                m: 0.8,
                border: 1.5,
                borderColor: "#D9D9D9",
                borderRadius: 3,
              }}
            >
              <Box>
                <Typography variant="h6">Usuario Aprobador</Typography>
              </Box>
              <Statements
                title={"Nombre del participante	"}
                text={
                  props.docsStackPrerre.PrerequisiteApprovalUserFullName ?? ""
                }
              />
              <Statements
                title={"Fecha límite"}
                text={UTCtoLocal(
                  props.docsStackPrerre?.UploadPrerequisitesDeadline,
                  false
                )}
              />
              <Statements
                title={"Estatus"}
                text={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Circle
                      sx={{ mb: "-1px", mr: 1 }}
                      fontSize="small"
                      style={{
                        color:
                          statusDocsUploadPendiente !== 0
                            ? "#C5C8CD"
                            : statusDocsRechazado !== 0
                            ? "#DA2F2F"
                            : statusDocsAproved === 0
                            ? "#3E8F41"
                            : statusDocsUpload === 0
                            ? "#3E8F41"
                            : "#C5C8CD",
                      }}
                    />
                    {statusDocsUploadPendiente !== 0
                      ? "Pendiente Validación"
                      : statusDocsRechazado !== 0
                      ? "Rechazado"
                      : statusDocsAproved === 0
                      ? "Aprobado"
                      : statusDocsUpload === 0
                      ? "Aprobado"
                      : "Pendiente Validación"}
                  </Box>
                }
              />
              <Statements
                title={"Reenvío de Correo Electrónico"}
                text={
                  <Tip
                    title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <IconButton
                      onClick={() =>
                        sendMailUser(
                          props.docsStackPrerre?.PrerequisiteApprovalUserId
                        )
                      }
                      disabled={validacion()}
                      sx={{ color: "#000000" }}
                    >
                      <ForwardToInboxIcon />
                    </IconButton>
                  </Tip>
                }
              />
            </Box>
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
}

const Statements = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle1" textAlign={"right"}>
          {props.text}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};
