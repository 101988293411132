import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { InboxTableHeader } from "./InboxTableHeader";
import { EmptyTable } from "./EmptyTable";
import { NoResults } from "./NoResults";
import {
  getFiles,
  getFile,
  getGlobalFiles,
  setFiles,
  editStack,
  resetFile,
  resetNewFile,
  resetDocumentEnlace,
  resetDocument64,
  getFileCustomInfoMasive,
  resetUsersPrevious,
  resetParticipantsMassive,
} from "../../store/actions";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { UTCtoLocal } from "../../utils";
import InboxResponsive from "./InboxResponsive";
import { Alert, AlertConfirm } from "../../components/Alert";
import { renderToString } from "react-dom/server";
import { Icon } from "@iconify/react";
import { FileFlowService } from "../../services/FileFlowService";
import { GenericTableSelection } from "../../components/GenericTableSelection";
import { GenericTable } from "../../components/GenericTable";
import { CustomModalAlert } from "../../components/CustomModalAlert";
moment.locale("es");

const columns = [
  {
    Header: "Tipo de Solicitud",
    accessor: "TypeSolicitud",
    width: 50,
  },
  {
    Header: "Folio",
    accessor: "invoice",
    width: 5,
  },
  {
    Header: "Nombre documental",
    accessor: "product",
    width: 200,
  },
  {
    Header: "Nombre del solicitante",
    accessor: "applicant",
    width: 80,
  },
  {
    Header: "Fecha de creación",
    accessor: "creationDate",
    width: 120,
  },
  {
    Header: "Estatus",
    accessor: "status",
    width: 120,
  },
  {
    Header: "Fecha límite",
    accessor: "validity",
    width: 60,
  },
  {
    Header: "Estado",
    accessor: "stage",
    width: 100,
  },
];

export const InboxTable = (props) => {
  const { searching } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [comentarios, setComentarios] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [folioSelectedSignature, setFolioSelectedSignature] = useState([]);
  const filesState = useSelector((state) => state.Files);
  const UsernameState = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Username
  );
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );

  const dispatch = useDispatch();
  const formMethods = useForm();
  const watchinputSearch = formMethods.watch("inputSearch");
  const watchstartDate = formMethods.watch("startDate");
  const watchfinishDate = formMethods.watch("finishDate");
  const watchFilters = formMethods.watch("filtros");

  const getStringDate = (date) => {
    return date instanceof Date && !isNaN(date.valueOf())
      ? date.toISOString()
      : "";
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const delteExpediente = async () => {
    try {
      setLoadingCancel(true);
      let Body = {
        File_Ids: folioSelectedSignature,
        Comments: comentarios,
      };
      const fileFlowService = FileFlowService.getInstance();
      const fileAprobatewData = await fileFlowService.cancelWorkFlowMasive({
        Body,
      });
      if (fileAprobatewData.IsOK === true) {
        Alert({
          icon: "success",
          title: "Solicitudes canceladas con éxito",
          okbtntext: "Aceptar",
        });
        setLoadingCancel(false);
        if (!searching) {
          dispatch(
            getFiles({
              SearchText: "",
              FechaInicio: "",
              FechaFin: "",
              Page: 1,
            })
          );
        } else {
          dispatch(
            getGlobalFiles({
              SearchText: "",
              FechaInicio: "",
              FechaFin: "",
              Page: 1,
              Filters: null,
            })
          );
          dispatch(setFiles([]));
          setdata([]);
        }
        handleClickOpen();
        setComentarios("");
      } else {
        Alert({
          icon: "error",
          text: fileAprobatewData.Messages,
          okbtntext: "Aceptar",
        });
        setLoadingCancel(false);
        handleClickOpen();
      }
    } catch (error) {
      Alert({
        icon: "error",
        text: error.message,
        okbtntext: "Aceptar",
      });
      setLoadingCancel(false);
      handleClickOpen();
      setComentarios("");
      console.log("errorDelete", error);
    }
  };

  const flujoExpendiente = (row) => {
    dispatch(resetFile());
    dispatch(resetDocumentEnlace());
    dispatch(resetDocument64());
    dispatch(resetUsersPrevious());
    dispatch(resetParticipantsMassive());
    const folio = row.cells
      ? !searching
        ? row?.cells[2].value
        : row?.cells[1].value
      : row.invoice;
    const stage = row.values ? row?.values.status : row.status;
    const aplicaMasivo = row.original?.AplicaMasivo ?? row.AplicaMasivo;
    const currentUserRow = row.original?.CurrentUser ?? row.CurrentUser;
    sessionStorage.removeItem("OTP");
    sessionStorage.removeItem("viewDocument");

    if (
      props.searching ||
      !aplicaMasivo ||
      (aplicaMasivo &&
        stage !== "Firma de documentos" &&
        stage !== "OTP Evidencias firma de documentos" &&
        stage !== "Evidencias firma de documentos") ||
      UsernameState !== currentUserRow
    ) {
      console.log("ENTRA AQUI", row, stage, folio);
      RedirectAtions(row, stage, folio);
    } else {
      if (
        row?.original?.typeSign === "Avanzada" ||
        row.typeSign === "Avanzada"
      ) {
        console.log("ENTRA AQUI", row, stage, folio);
        RedirectAtions(row, stage, folio);
      } else {
        console.log("ENTRA AQUI 0");
        AlertConfirm(
          {
            icon: "warning",
            // text: "Esta es una solicitud en grupo ¿Deseas firmar las N solicitudes?",
            html: renderToString(
              <Box>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  Esta es una solicitud en grupo
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                >
                  ¿Deseas firmar las{" "}
                  <b>{row?.original?.conteoMasivo || row?.conteoMasivo}</b>{" "}
                  solicitudes?
                </Typography>
              </Box>
            ),
            okbtntext: "Si",
            notbtntext: "No",
          },
          (result) => {
            console.log("alert result: ", result);
            if (result.isConfirmed) {
              console.log("Confirmed alert: ", result);
              sessionStorage.removeItem("ctrlExpediente");
              dispatch(editStack(false));
              dispatch(getFile({ FileId: folio }));
              navigate("/inbox/InboxSign", {
                state: { ActiveMassiveSignature: aplicaMasivo },
              });
            } else if (result.dismiss === "cancel" && result.isDismissed) {
              console.log("Cancel alert: ", result);
              RedirectAtions(row, stage, folio);
            }
          }
        );
      }
    }
  };

  const RedirectAtions = (row, stage, folio) => {
    dispatch(resetDocumentEnlace());
    dispatch(resetDocument64());
    if (stage === "Nuevo expediente") {
      sessionStorage.setItem("FileId", folio);
      if (
        row.original?.productCode === "Masiva" &&
        (row?.original?.typeSign !== "Avanzada" || row.typeSign === "Avanzada")
      ) {
        dispatch(
          getFileCustomInfoMasive({
            Body: {
              FileId: folio,
            },
          })
        );
      } else {
        dispatch(getFile({ FileId: folio }));
      }
      dispatch(editStack(true));
      sessionStorage.setItem("ctrlExpediente", true);
      sessionStorage.setItem(
        "ctrlSolicitudMasiva",
        JSON.stringify(row.original?.productCode === "Masiva" ? true : false)
      );
      navigate("/inbox/new-file");
    } else {
      sessionStorage.removeItem("ctrlExpediente");
      dispatch(editStack(false));
      dispatch(getFile({ FileId: folio }));
      if (props.searching) {
        navigate("/inbox/file-information-search");
      } else {
        navigate("/inbox/file-information");
      }
    }
  };

  const handleChangePage = (e, n) => {
    if (n !== pageCount) {
      const filter = {
        SearchText: watchinputSearch ?? "",
        FechaInicio: getStringDate(watchstartDate) ?? null,
        FechaFin: getStringDate(watchfinishDate) ?? null,
        Page: n,
      };
      if (!searching) {
        dispatch(getFiles(filter));
      } else {
        dispatch(getGlobalFiles(filter));
      }
      setPageCount(n);
    }
  };

  const handleFilter = formMethods.handleSubmit(() => {
    if (watchinputSearch || watchstartDate || watchfinishDate) {
      const filter = {
        SearchText: watchinputSearch ?? "",
        FechaInicio: getStringDate(watchstartDate) ?? null,
        FechaFin: getStringDate(watchfinishDate) ?? null,
        Page: 0,
      };
      if (!searching) {
        dispatch(getFiles(filter));
      } else {
        const filts = watchFilters ? watchFilters : null;
        dispatch(
          getGlobalFiles({
            ...filter,
            Filters: filts,
          })
        );
      }
      setPageCount(1);
    } else {
      if (!searching) {
        dispatch(
          getFiles({
            SearchText: "",
            FechaInicio: "",
            FechaFin: "",
            Page: 1,
          })
        );
      } else {
        dispatch(
          getGlobalFiles({
            SearchText: "",
            FechaInicio: "",
            FechaFin: "",
            Page: 1,
            Filters: null,
          })
        );
      }
    }
  });

  useEffect(() => {
    setResetFilter(true);
    dispatch(resetFile());
    dispatch(resetNewFile());
    dispatch(editStack(false));
    if (!searching) {
      dispatch(
        getFiles({
          SearchText: "",
          FechaInicio: "",
          FechaFin: "",
          Page: 1,
        })
      );
    } else {
      dispatch(
        getGlobalFiles({
          SearchText: "",
          FechaInicio: "",
          FechaFin: "",
          Page: 1,
          Filters: null,
        })
      );
    }
    setPageCount(1);
  }, [searching]);

  //Efecto para mapear la data de la api guardada en redux para la tabla
  useEffect(() => {
    if (filesState?.items?.fileCustomDataResponse) {
      const filesTable = filesState.items.fileCustomDataResponse.map((file) => {
        return {
          invoice: file.Id,
          product: file.Reference,
          applicant: file?.Applicant,
          color: file.ColorMasiveGroup,
          creationDate: UTCtoLocal(file.CreationDate, true),
          validity: UTCtoLocal(file.Vigencia, false),
          typeSign: file.TipoFirma,
          status: file.Status,
          stage: file.Tooltip,
          userCreation: rolUser === "Operador" ? false : true ,
          TypeSolicitud:
            file.IsFather === true ? (
              <Box
                sx={{
                  width: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top"
                  title={"Solicitud Masivo"}
                >
                  <Icon
                    color={file.ColorMasiveGroup}
                    icon="heroicons:users"
                    fontSize={25}
                  />
                </WhiteTooltip>
              </Box>
            ) : (
              <Box
                sx={{
                  width: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top"
                  title={"Solicitud Individual"}
                >
                  <Icon
                    color={'black'}
                    icon="uil:user"
                    fontSize={25}
                  />
                </WhiteTooltip>
              </Box>
            ),
          conteoMasivo: file.ConteoMasivo,
          statusVigencia: file.StatusVigencia,
          productCode: file.ProductCode,
          AplicaMasivo: file.AplicaMasivo,
          CurrentUser: file.CurrentUser,
          CreationUser: file.CreationUser,
        };
      });
      return setdata(filesTable);
    } else {
      return setdata([]);
    }
  }, [filesState?.loading, searching]);

  const renderTable = () => {
    return (
      <>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <InboxResponsive data={data} fileFlow={flujoExpendiente} />
        </Box>
        <Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {!searching ? (
              <GenericTableSelection
                SelectedAll={false}
                Columns={columns}
                Data={data}
                onClickRow={flujoExpendiente}
                ShowPagination={true}
                TotalRows={filesState?.items?.CountItems}
                TotalPages={filesState.items?.ConfPaginado?.CountPage}
                PageCurrent={pageCount}
                onClickChangePage={handleChangePage}
                ClickSelectedRows={(data) => {
                  var array = data.map((item) => {
                    return item.original.invoice;
                  }); // Se filtra solo el objeto original
                  setFolioSelectedSignature(array);
                }}
              />
            ) : (
              <GenericTable
                Columns={columns}
                Data={data}
                onClickRow={flujoExpendiente}
                ShowPagination={true}
                TotalRows={filesState?.items?.CountItems}
                TotalPages={filesState.items?.ConfPaginado?.CountPage}
                PageCurrent={pageCount}
                onClickChangePage={handleChangePage}
              />
            )}
          </Box>
        </Box>
        <CustomModalAlert
          Open={open}
          Title={"Motivo de la cancelación"}
          LeftButtonText="Cancelar"
          LeftButtonAction={() => handleClickOpen()}
          RightButtonText="Enviar"
          Comments={true}
          ValueComment={comentarios}
          CommentPlaceHolder="Motivo de la cancelación"
          setValueComment={setComentarios}
          RightButtonAction={() => delteExpediente()}
        />
      </>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ mt: 1 }}>
          <InboxTableHeader
            delteExpediente={handleClickOpen}
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
            searching={searching}
            handleFilter={handleFilter}
          />
          {filesState?.loading ? (
            <Box sx={{ my: 10, display: "grid", placeContent: "center" }}>
              <CircularProgress
                size={60}
                sx={{ margin: "auto", display: "flex" }}
              />
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1">Buscando</Typography>
              </Box>
            </Box>
          ) : (
            (function () {
              if (
                location.pathname === "/inbox/files" &&
                (watchinputSearch || watchstartDate || watchfinishDate) &&
                data.length === 0
              ) {
                return <NoResults />;
              }
              if (
                location.pathname === "/inbox/files" &&
                !formMethods.formState.isDirty &&
                data.length === 0
              ) {
                return <EmptyTable />;
              }
              if (data.length === 0) {
                return <NoResults />;
              }
              return renderTable();
            })()
          )}
        </Box>
      </LocalizationProvider>
    </FormProvider>
  );
};
