import React, { useEffect, useState, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Pagination as Pag,
  PaginationItem,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  FiberManualRecord,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { FormProvider, useForm } from "react-hook-form";

import { WhiteTooltip } from "./WhiteTooltip";
import { CustomInputField } from "./CustomInputField.jsx";

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Pagination = styled(Pag)(({ theme }) => ({
  "& ul": { justifyContent: "flex-end" },
  "& ul li button": { fontSize: "14px" },
  "& .Mui-selected": {
    backgroundColor: (theme) => `${theme.palette.secondary.main40} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    "& ul": { justifyContent: "center" },
  },
}));

export const GenericTable = (props) => {
  const buttonSubmit = useRef();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Formulario dinamico
  const formMethods = useForm();
  const { control, handleSubmit, register, formState } = formMethods;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: props.Columns,
        data: props.Data,
        title: "",
        initialState: {
          pageIndex: 0,
          hiddenColumns: props.HiddenColumns ?? ["stage"],
        },
      },
      useSortBy
    );

  useEffect(() => {
    if (props.sendDataActive) {
      props.setSendDataActive(null);
      buttonSubmit.current.click();
    }
  }, [props.sendDataActive]);

  return (
    <FormProvider {...formMethods}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <TableContainer
            component={Paper}
            elevation={5}
            sx={{ maxHeight: 600 }}
          >
            <Table {...getTableProps()}>
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <WhiteTooltip
                        title={column?.Toltip}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <TableCell
                          sx={{
                            padding: 1.5,
                            width:
                              column?.type === "check"
                                ? 85
                                : column?.type === "Option"
                                ? 230
                                : "auto",
                          }}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent:
                                column?.type === "check" ||
                                column?.Header === "Clave_Solicitud_Consecutiva"
                                  ? "center"
                                  : "",
                              fontSize: "14px",
                              minWidth:
                                column?.type === "check"
                                  ? 85
                                  : column?.type === "Option"
                                  ? 230
                                  : "auto",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: 14,
                                minWidth: 80,
                                fontWeight: 500,
                                color: "primary.mainLyrics",
                              }}
                            >
                              {column.render("Header")}
                            </Typography>
                          </Box>
                        </TableCell>
                      </WhiteTooltip>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRowStyled
                      {...row.getRowProps()}
                      onClick={() => {
                        props.onClickRow(row);
                      }}
                      sx={{
                        borderBottom: "none !important",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        if (cell.column.type === "Editable") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              <CustomInputField
                                inputType="text"
                                control={control}
                                register={register}
                                name={`EtiquetaCaptura_${row.original.NombreEtiqueta}`}
                                placeholder={cell.column.Header}
                                validations={{
                                  required: row.original.Required,
                                }}
                                error={
                                  !!formState.errors[
                                    `EtiquetaCaptura_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `EtiquetaCaptura_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={row.original.Required}
                                defaultValue={row.original.NombreEtiqueta.toUpperCase()}
                              />
                            </TableCell>
                          );
                        } else if (cell.column.type === "EditableRole") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              <CustomInputField
                                inputType="text"
                                control={control}
                                register={register}
                                name={`RoleUser_${row.original.NombreEtiqueta}`}
                                placeholder={cell.column.Header}
                                validations={{
                                  required: row.original.Required,
                                }}
                                error={
                                  !!formState.errors[
                                    `RoleUser_${row.original.Role}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `RoleUser_${row.original.Role}`
                                  ]?.message
                                }
                                required={row.original.Required}
                                defaultValue={row.original.Role}
                              />
                            </TableCell>
                          );
                        } else if (cell.column.type === "Option") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                paddingLeft: 1,
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              <CustomInputField
                                inputType="select"
                                control={control}
                                register={register}
                                name={`TipoDato_${row.original.NombreEtiqueta}`}
                                placeholder={cell.column.Header}
                                optionsList={cell.column.listOption}
                                validations={{
                                  required: row.original.Required,
                                }}
                                error={
                                  !!formState.errors[
                                    `TipoDato_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `TipoDato_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={row.original.Required}
                                defaultValue={row.original.TipoDato ?? ""}
                              />
                            </TableCell>
                          );
                        } else if (cell.column.type === "check") {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                padding: 1.5,
                                borderBottom: "none",
                                fontSize: "14px !important",
                                textAlign: "center",
                              }}
                            >
                              <CustomInputField
                                inputType="check"
                                control={control}
                                register={register}
                                name={`CheckCaptura_${row.original.NombreEtiqueta}`}
                                label={""}
                                validations={{ required: false }}
                                error={
                                  !!formState.errors[
                                    `CheckCaptura_${row.original.NombreEtiqueta}`
                                  ]
                                }
                                helperText={
                                  formState.errors[
                                    `CheckCaptura_${row.original.NombreEtiqueta}`
                                  ]?.message
                                }
                                required={false}
                                defaultValue={row.original.Required ?? false}
                              />
                            </TableCell>
                          );
                        } else {
                          if (cell.column.id === "status") {
                            return (
                              <TooltipTableCell
                                row={row}
                                statusVigencia={
                                  cell.row.original.statusVigencia
                                }
                                cell={cell}
                                key={cell.column.id}
                                enterTouchDelay={0}
                              />
                            );
                          }
                          if (cell.column.id === "estatus") {
                            return (
                              <TooltipTableCellEstatus
                                row={row}
                                statusRow={cell.row.original.estatus}
                                cell={cell}
                                key={cell.column.id}
                                enterTouchDelay={0}
                              />
                            );
                          }
                          if (cell.column.id === "statusError") {
                            return null;
                          }
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              sx={{
                                textAlign:
                                  cell?.column?.Header ===
                                  "Clave_Solicitud_Consecutiva"
                                    ? "center"
                                    : "",
                                borderBottom: "none",
                                fontSize: "14px !important",
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {props.ShowPagination === undefined ||
        props.ShowPagination === null ||
        props.ShowPagination === true ? (
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              px: { xs: 0, sm: 1 },
              mt: 2,
            }}
          >
            <Pagination
              shape="rounded"
              count={props.TotalPages}
              page={props.PageCurrent}
              onChange={(event, page) => {
                props.onClickChangePage(event, page);
              }}
              siblingCount={xsScreen ? 0 : 1}
              boundaryCount={xsScreen ? 0 : 1}
              size={xsScreen ? "small" : "medium"}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: PreviusPag, next: NextPag }}
                  {...item}
                />
              )}
            />
          </Box>
        ) : null}
        <Button
          id={`btn_Save`}
          ref={buttonSubmit}
          onClick={handleSubmit(props.HandleSendData)}
          color="primary"
          style={{ marginTop: "0.5em", display: "none" }}
        >
          Send Data
        </Button>
      </LocalizationProvider>
    </FormProvider>
  );
};

const PreviusPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ mt: 0.5 }}>
        <ArrowBackIos sx={{ fontSize: "14px" }} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Anterior</Box>
    </Box>
  );
};

const NextPag = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>Siguiente</Box>
      <Box sx={{ mt: 0.5 }}>
        <ArrowForwardIos sx={{ fontSize: "14px" }} />
      </Box>
    </Box>
  );
};

const TooltipTableCell = (props) => {
  const [open, setopen] = useState(false);
  console.log("statusstatus", props.row.original.stage);
  const tooltipTitle = (
    <Typography variant="caption">{props.row.original.stage}.</Typography>
  );

  const colorStatus = () => {
    if (props.statusVigencia === "EnTiempo") {
      return "success.dark";
    } else if (props.statusVigencia === "PorVencer") {
      return "#FFDB7E";
    } else if (props.statusVigencia === "Vencido") {
      return "primary.main";
    }
  };

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{ borderBottom: "none", padding: 1 }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "status" &&
        props.row.original.stage === true ? (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="el:ok-circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        ) : props.row.original.stage === false ? (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="zondicons:close-outline"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        ) : (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <FiberManualRecord
              sx={{
                mb: "-1px",
                mr: 1,
                color: colorStatus(),
                fontSize: 13,
              }}
            />
          </WhiteTooltip>
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};

const TooltipTableCellEstatus = (props) => {
  const [open, setopen] = useState(false);
  const tooltipTitle = (
    <Typography variant="caption">
      {props.row.original.statusError ??
        "Los datos no estan completos, todos los datos son requeridos."}
    </Typography>
  );

  return (
    <TableCell
      {...props.cell.getCellProps()}
      sx={{
        borderBottom: "none",
        padding: 1,
      }}
      onMouseEnter={() => setopen(true)}
      onMouseLeave={() => setopen(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
        }}
      >
        {props.cell.column.id === "estatus" && props.statusRow === false ? (
          <WhiteTooltip placement="top" open={open} title={"Datos completos."}>
            <Icon
              icon="el:ok-circle"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#0FA958",
              }}
            />
          </WhiteTooltip>
        ) : (
          <WhiteTooltip placement="top" open={open} title={tooltipTitle}>
            <Icon
              icon="zondicons:close-outline"
              style={{
                mb: "-1px",
                mr: 1,
                fontSize: 13,
                color: "#FF4842",
              }}
            />
          </WhiteTooltip>
        )}
        {props.cell.render("Cell")}
      </Box>
    </TableCell>
  );
};
