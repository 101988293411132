import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStylesTimer = makeStyles((theme) => ({
    timer: {
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '130%',
        color: '#000000',
        // margin: '3rem 0',
        width: '100%',
        display: 'flex',
        // height: '12%',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    digits: {
        fontFamily: 'Montserrat',
        color: '#FFFFFF',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "90px",
        lineHeight: "130%",
    },
    miliSec: {
        color: '#e42a2a',
        width: '15px',
    }
}));

export const TimerCountdown = (props) => {
    const classes = useStylesTimer();

    const { startingMinutes = 0, startingSeconds = 0, onlySeconds = false, returnSeconds } = props;
    const [mins, setMinutes] = useState(startingMinutes);
    const [secs, setSeconds] = useState(startingSeconds);

    useEffect(() => {
        let sampleInterval = setInterval(() => {
            if (secs > 0) {
                var currentSecTime = secs - 1;
                // console.log("TimerCountdown: ++++----:currentSecTime ", currentSecTime)
                setSeconds(currentSecTime);
                returnSeconds(currentSecTime)
            }
            if (secs === 0) {
                if (onlySeconds && secs === 0) {
                    clearInterval(sampleInterval);
                }
                else if (!onlySeconds && mins === 0) {
                    clearInterval(sampleInterval);
                } else {
                    setMinutes(mins - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(sampleInterval);
        };
    });

    return (
        <Box sx={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: 'center', background: '#000000' }} >
            <Box sx={{
                display: "flex", width: '140px',
                height: '125px', background: 'rgba(0, 0, 0, 0.51)',
                borderRadius: '50%', border: '3px solid #FFFFFF',
                alignItems: 'center', justifyContent: 'center', color: "#FFFFFF"
            }} >
                {onlySeconds && startingSeconds > 0 ?
                    <span className={classes.digits}>
                        {secs}
                        {/* {secs < 10 ? `0${secs}` : secs} */}
                    </span>
                    : onlySeconds ? <Box sx={{ color: "red" }}>Debe inicializar los segundos.</Box>
                        : null
                }
                {(!onlySeconds && startingMinutes == 0 && startingSeconds == 0) || !(mins && secs) ? "" : (
                    <span className={classes.digits}>
                        {" "}
                        {mins}:{secs < 10 ? `0${secs}` : secs}
                    </span>
                )}
            </Box>
        </Box >
    );
}