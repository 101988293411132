import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class WorkFlowService {
  static instance;

  constructor() {
    this.baseUrl = "workflow";
  }

  static getInstance() {
    if (!WorkFlowService.instance) {
      WorkFlowService.instance = new WorkFlowService();
    }
    return WorkFlowService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${
          respObject.TansactionId ? respObject.TansactionId : ""
        } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${
          respObject.TansactionId ? respObject.TansactionId : ""
        } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async getStageRecords(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetStageRecords`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }

  async getStageRecordsDocument(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetStageRecordsDocument`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }
    
  async ContinueWorkFlow(data) {
    console.log("ContinueWorkFlow request: ", `${this.baseUrl}/ContinueWorkFlow`)
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/ContinueWorkFlow`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }
    
  async CancelWorkFlow(data) {
    console.log("CancelWorkFlow request: ", `${this.baseUrl}/CancelWorkFlow`)
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/CancelWorkFlow`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }
}