import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class AuthService {
  static instance;

  constructor() {
    this.baseUrl = "authentication/authentication";
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  };

  async handleResponse(response) {
    let respObject = response.data.data; //BodySecurityResponse(response.data);

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  };


  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async authenticationNoCrypto(data) {
    const response = await GenericService.post({
      endpoint: this.baseUrl,
      data,
      config: {
        headers: {
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json;charset=UTF-8",
        },
      },
    });
    const userAuthenticate = await this.handleResponse(response);
    sessionStorage.clear();
    sessionStorage.setItem("token", userAuthenticate.Body.Token);
    return userAuthenticate;
  };

  async authentication(data) {
    const response = await GenericService.post({
      endpoint: "authentication/authentication",
      data: Config.setParameters(data, "", "", ""),
      config: Config.configNoAuth(),
    });
    const userAuthenticate = await this.handleResponse(response);
    sessionStorage.clear();
    sessionStorage.setItem(
      //Almacenar token opcion 1: SessionStorage
      "token",
      userAuthenticate.Body.Token
    );
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "userName",
      `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName ?? ''}`
    );
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "userNameDisplay",
      `${userAuthenticate.Body.UserLoginData.Name}`
    );
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "Rol",
      `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
    );
    return userAuthenticate;
  }

  async authenticationEncripted(data) {
    // console.log("Request: ", data)
    const response = await GenericService.post({
      endpoint: "authentication/authentication",
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configNoAuth(),
    });

    const userAuthenticate = await this.handleResponseForceEncripted(response);

    // console.log("userAuthenticate: response: ", userAuthenticate)
    sessionStorage.clear();
    sessionStorage.setItem(
      //Almacenar token opcion 1: SessionStorage
      "token",
      userAuthenticate.Body.Token
    );
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "userName",
      `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName ?? ''} ${userAuthenticate.Body.UserLoginData.MotherLastName ?? ''}`
    );
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "userNameDisplay",
      `${userAuthenticate.Body.UserLoginData.Name}`
    );
    // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
    sessionStorage.setItem(
      //Borrar al cerrar sesión
      "Rol",
      `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
    );

    return userAuthenticate;
  }

   
  async resetPassword(data) {
    console.log('data api', data)
    const response = await GenericService.post({
      endpoint: `user/RegisterChangePassword`,
      data: Config.setParametersForceEncripted(data, false)
    });
    // const productId = await this.handleResponseForceEncripted(response);
    return response;
  }

  async validatePIN(data) {
    console.log('DATAPIN', data)
    const response = await GenericService.post({
      endpoint: `validation/ValidatePINChangePassword`,
      data: Config.setParametersForceEncripted(data, false)
    });
    // const productId = await this.handleResponseForceEncripted(response);
    return response;
  };


  async recoverPassword(data) {
    console.log(data)
    const response = await GenericService.post({
      endpoint: `user/RecoverPassword`,
      data: data 
    });
    // const productId = await this.handleResponseForceEncripted(response);
    return response;
  };


}
