import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { toBase64 } from "../../../utils/signature";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  restartFlow,
  uplodadNewDocumentVersion,
  validateDocuments,
} from "../../../store/actions";
import { FileService } from "../../../services/FileService";

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return { wordUrl, wordPdfB64 };
};

const convertWordToB64 = async (file) => {
  const fileConverted = await toBase64(file);
  const base64result = fileConverted.split(",")[1];
  const wordToPdf = await convertWordToPdf(base64result);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return { base64result, wordUrl };
};

export default function RevisarDocumento({ setValue }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const document = useSelector((state) => state.File);
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );
  const statusAdmin =
    userData?.Username === document?.item?.FileData?.CreationUser;

  const userName = userData.Name + userData?.FatherLastName + (userData?.MotherLastName ?? '');

  const [docNewVersion, setDocNewVersion] = useState();
  const [pdfBase64, setpadfBase64] = useState(null);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [open, setOpen] = useState(false);
 
  const convertDocument = React.useCallback(async () => {
    try {
      const fileConverted = await toBase64(docNewVersion);
      const base64result = fileConverted.split(",")[1];
      setpadfBase64(base64result);
    }
    catch (err) {
      setpadfBase64(new Error("Error al convertir archivo"));
    }
  }, [docNewVersion]);

  const setWordToB64 = React.useCallback(async () => {
    try {
      if (docNewVersion) {
        const { base64result } = await convertWordToB64(docNewVersion);
        const docConvertido = convertWordB64ToPdfB64(base64result);
        docConvertido.then((value) => {
          setpdfUrl(value.wordUrl);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [docNewVersion]);

  useEffect(() => {
    if (docNewVersion) {
      console.log("comentData.Body.FileName: ", docNewVersion.name)
      var extension = docNewVersion.name.split(".").length > 1 ? docNewVersion.name.split(".")[1] : "pdf"
      if (extension === "docx") {
      // if (docNewVersion.name.split(".")[1] === "docx") {
        setWordToB64();
      } else {
        let url = URL.createObjectURL(docNewVersion);
        setpdfUrl(url);
        convertDocument();
      }
    }
  }, [docNewVersion, convertDocument]);

  // const handleVersionDocument = async () => {
  //   const Body = {
  //     B64Content: pdfBase64,
  //     FileId: document?.item?.FileData?.Id,
  //     Actor_Id: null,
  //     SkipActor: true,
  //     SectionId: document?.document?.DocumentData?.Sections[0].Section_Id,
  //     Extension: `.${docNewVersion?.type.split("/")[1]}`,
  //     Skip: false,
  //     SkipReason: "",
  //     Metadata: [],
  //     ContinueWorkFlow: false,
  //     Geolocation: null,
  //     DeferLoad: false,
  //     OmitirMergeVideo: false,
  //     OmitirRemovebg: false,
  //     AsignarEstatusCarga: false,
  //   };

  //   if (statusAdmin) {
  //     dispatch(uplodadNewDocumentVersion({ Body }));
  //     dispatch(restartFlow({
  //       Body: {
  //         Document_Id: document?.document?.DocumentData?.Id
  //       }
  //     }))
  //     setValue(0);
  //   } else {
  //     dispatch(uplodadNewDocumentVersion({ Body }));
  //     dispatch(
  //       validateDocuments({
  //         Body: {
  //           Document_Id: document?.document?.DocumentData?.Id,
  //           Approved: false,
  //           Comments: "",
  //           ValidateFileComplete: true,
  //         },
  //       })
  //     )
  //     setValue(0);
  //   }
  // };


  const handleClickOpen = () => {
    return navigate('/inbox/files');
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ p: 1, minWidth: 255 }}>
            <a
              href={document?.enlace}
              style={{ textDecoration: "none" }}
              download={document?.document_64?.FileName ?? ""}
            >
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                color="secondary"
              >
                Descargar documento
              </Button>
            </a>
          </Box> */}

          {/* <Box
            sx={{ display: "flex", alignItems: "center", p: 1, minWidth: 255 }}
          >
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              startIcon={<FileUploadIcon />}
              color="secondary"
              onClick={handleKeyInput}
            >
              <input
                ref={docNewRef}
                onChange={handledocNewVersion}
                style={{ visibility: "hidden", width: 0 }}
                type="file"
                accept={".docx, .pdf"}
              />
              Subir documento
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ p: 1, minWidth: 175, mr: 5 }}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            onClick={() => handleClickOpen()}
          >
            Regresar
          </Button>
        </Box>
        {/* <Box
          sx={{ display: "flex", alignItems: "center", p: 1, minWidth: 255 }}
        >
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            startIcon={<FileUploadIcon />}
            color="secondary"
            onClick={handleKeyInput}
          >
            <input
              ref={docNewRef}
              onChange={handledocNewVersion}
              style={{ visibility: "hidden", width: 0 }}
              type="file"
              accept={".docx, .pdf"}
            />
            Subir documento
          </Button>
        </Box> */}
      </Box>

      <iframe
        id='ifEditor'
        title="Editor de texto"
        style={{ width: '100%', height: '100vh' }}
        src={`${process.env.REACT_VISOR_DSONBOARDING}?docId=${document?.document?.DocumentData?.Id}&rol=${statusAdmin ? 'Admin' : 'User'}&fileId=${document?.item?.FileData?.Id}&sectionId=${document?.document?.DocumentData?.Sections[0].Id}&docName=${document?.document?.DocumentData.Name}&user=${userName}&token=${sessionStorage.token}`}
      />
      {/* {!viewData ? (
        <Box sx={{ display: 'flex', p: '2', justifyContent: 'center' }}>
          <Button size="small" onClick={() => setViewData(!viewData)}>
            <Typography
              sx={{ color: 'primary.main80', fontWeight: 500 }}
            >
              Ver mas informacion
            </Typography>
          </Button>
        </Box>
      ) : (
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item md={11} sx={12}>
            <Box sx={{ display: 'flex', p: '2', p: 2, justifyContent: 'center' }}>
              <Button size="small" onClick={() => setViewData(!viewData)}>
                <Typography
                  sx={{ color: 'primary.main80', fontWeight: 500 }}
                >
                  Ocultar información
                </Typography>
              </Button>
            </Box>
            <Box sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: 450 }}>
                Información del documento
              </Typography>
            </Box>
            <Box sx={{ ml: 3 }}>
              <Metadatos fileState={document} />
            </Box>
            <Box sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: 450 }}>
                Paticipantes del documento
              </Typography>
              <Box sx={{ ml: 3 }}>
                <FileInformationFirmantes />
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 450 }}>
                Bítacora del documento
              </Typography>
              <Box sx={{ ml: 3 }}>
                <Bitacora />
              </Box>
            </Box>
            <Comentarios comment={true} />
          </Grid>
        </Grid>
      )} */}

      <Dialog open={open} onClose={handleClickOpen} maxWidth={"xs"} fullWidth>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1">
              ¿Estas seguro que deseas salir sin guardar cambios?
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", p: 2, mt: 1 }}>
            <Button
              size="medium"
              onClick={() => handleClickOpen()}
              sx={{
                borderTop: 1,
                minWidth: 150,
                borderColor: "secondary.main40",
              }}
            >
              Cancelar
            </Button>
            <Button
              size="medium"
              onClick={() => handleClickOpen()}
              sx={{
                borderTop: 1,
                minWidth: 150,
                borderLeft: 1,
                borderColor: "secondary.main40",
              }}
            >
              Aceptar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
