import { massiveConstants } from "../constants";
import { MassiveService } from "../../services/MassiveService";
import { Alert } from "../../components/Alert";

const request = (actionType) => {
    return {
        type: actionType,
    };
};

const success = (data, actionType) => {
    return {
        type: actionType,
        payload: data,
    };
};

const failure = (error, actionType) => {
    return {
        type: actionType,
        payload: error,
    };
};

export const GetFileCustomInfoMasive = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.FILE_GETINFO_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.GetFileCustomInfoMasive(data);
            dispatch(
                success(massiveData.Body, massiveConstants.FILE_GETINFO_SUCCESS)
            );
        } catch (error) {
            dispatch(failure(error.message, massiveConstants.FILE_GETINFO_FAILURE));
        }
    };
};

export const UploadDocumentParticipants = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.FILE_PARTICIPANT_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.UploadDocumentParticipants(data);
            console.log('massiveData', massiveData);
            dispatch(
                success(massiveData.Body.Response, massiveConstants.FILE_PARTICIPANT_SUCCESS)
            );
        } catch (error) {
            console.log('errorerror', error);
            dispatch(failure(error.message, massiveConstants.FILE_PARTICIPANT_FAILURE));
        }
    };
};

export const SkipPrerequisites = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.SKIP_PREREQUISITES_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.SkipPrerequisites(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.SKIP_PREREQUISITES_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.SKIP_PREREQUISITES_FAILURE));
        }
    };
};

export const UploadTemplate = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.UPLOAD_TEMPLATE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.UploadTemplate(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.UPLOAD_TEMPLATE_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.UPLOAD_TEMPLATE_FAILURE));
        }
    };
};

export const UploadTemplateMetaData = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.UPLOAD_TEMPLATE_DATA_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.UploadTemplateMetaData(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.UPLOAD_TEMPLATE_DATA_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.UPLOAD_TEMPLATE_DATA_FAILURE));
        }
    };
};

export const DisableTemplate = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.DISABLE_TEMPLATE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.DisableTemplate(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.DISABLE_TEMPLATE_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.DISABLE_TEMPLATE_FAILURE));
        }
    };
};

export const GetUploadMetadataTemplate = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.UPLOAD_METADATA_TEMPLATE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.GetUploadMetadataTemplate(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.UPLOAD_METADATA_TEMPLATE_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.UPLOAD_METADATA_TEMPLATE_FAILURE));
        }
    };
};

export const UploadMetadataBatchFile = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.UPLOAD_METADATA_BATCHFILE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.UploadMetadataBatchFile(data);
            console.log(massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.UPLOAD_METADATA_BATCHFILE_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.UPLOAD_METADATA_BATCHFILE_FAILURE));
        }
    };
};

export const addListDocsStackMassive = (document) => {
    return {
        type: massiveConstants.ADD_LISTDOC_STACK_MASSIVE,
        payload: document,
    };
};

export const resetListDocsStackMassive = () => {
    return {
        type: massiveConstants.RESET_LISTDOC_STACK_MASSIVE,
    };
};

export const setListDocsStackMassive = (document) => {
    return {
        type: massiveConstants.SET_LISTDOC_STACK_MASSIVE,
        payload: document,
    };
};

export const resetUsersPrevious = () => {
    return {
        type: massiveConstants.PREREQUISITOS_TEMPLATE_RESET,
    };
};

export const resetParticipantsMassive = () => {
    return {
        type: massiveConstants.PARTICIPANTS_TEMPLATE_RESET,
    };
};


export const UploadPrerequisitesBatchFile = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.PREREQUISITOS_TEMPLATE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.UploadPrerequisitesBatchFile(data);
            console.log('massiveDatamassiveData', massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.PREREQUISITOS_TEMPLATE_SUCCESS)
            );
        } catch (error) {
            console.log(error);
            dispatch(failure(error.message, massiveConstants.PREREQUISITOS_TEMPLATE_FAILURE));
        }
    };
};

export const resetReorderParticipantMassive = () => {
    return {
        type: massiveConstants.REODERDER_PARTICIPANT_MASSIVE_RESET,
    };
};

export const ReorderParticipantMassive = (data) => {
    return async (dispatch) => {
        dispatch(request(massiveConstants.REODERDER_PARTICIPANT_MASSIVE_REQUEST));
        try {
            const massiveService = MassiveService.getInstance();
            const massiveData = await massiveService.ReorderParticipantMassive(data);
            console.log('ReorderParticipantMassive', massiveData);
            dispatch(
                success(massiveData.Body, massiveConstants.REODERDER_PARTICIPANT_MASSIVE_SUCCESS)
            );
        } catch (error) {
            console.log('ReorderParticipantMassive',error);
            dispatch(failure(error.message, massiveConstants.REODERDER_PARTICIPANT_MASSIVE_FAILURE));
        }
    };
};