import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class MassiveService {
    static instance;

    constructor() {
        this.baseUrl = "BatchUpload";
    }

    static getInstance() {
        if (!MassiveService.instance) {
            MassiveService.instance = new MassiveService();
        }
        return MassiveService.instance;
    }

    async handleResponse(response) {
        let respObject = response.data.data;

        if (respObject.ResponseCode !== 0) {
            const responseObject = {
                message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
                    } ${respObject.statusText ? respObject.statusText : ""}`,
            };
            const error = respObject.Body || responseObject;
            return Promise.reject(error);
        }
        return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
    }

    async handleResponseForceEncripted(response) {
        console.log('handleResponseForceEncripted response ', response)
        var respObject = BodySecurityResponseForceEncripted(response.data.data);
        console.log('BodySecurityResponseForceEncripted respObject ', respObject)
        if (respObject.ResponseCode !== 0) {
            var responseObject = {
                message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
                    } ${respObject.statusText ? respObject.statusText : ""}`,
            };
            // console.log('BodySecurityResponseForceEncripted respObject IsOK responseObject ', responseObject)
            const error = respObject?.Body || responseObject;
            return Promise.reject(error);
        }

        return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
    }

    async UploadDocumentParticipants(data) {
        // console.log('Data participantes', data)
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/UploadParticipants`,
            data: data,
            // Config.setParametersForceEncripted(data, false),
            config: Config.configAuth(),
        });
        // console.log('RESPONSEParticipants', response)
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async UploadTemplate(data) {
        // console.log('.:UploadTemplate:.request ', data)
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/UploadTemplate`,
            data: data,
            // Config.setParametersForceEncripted(data, false),
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        // console.log('.:UploadTemplate:.dataRes ', dataRes)
        return dataRes;
    }

    async UploadTemplateMetaData(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/UploadTemplateData`,
            data: data,
            // Config.setParametersForceEncripted(data, false),
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async DisableTemplate(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/DisableTemplate`,
            data: data,
            // Config.setParametersForceEncripted(data, false),
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async GetUploadMetadataTemplate(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetUploadMetadataTemplate?file_Id=${data}`,
            config: Config.configDoc(),
        });
        console.log('.:GetUploadMetadataTemplate:. response', response)
        return response;
    }      

    async UploadMetadataBatchFile(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/UploadMetadataBatchFile`,
            data: data,
            // Config.setParametersForceEncripted(data, false),
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async InitializeBatchUpload(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/InitializeBatchUpload`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        console.log('INICIARFLUJO', dataRes)
        return dataRes;
    }

    // APIS REQUISITOS PREVIOS
    async GetUploadPrerequisitesTemplate(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetUploadPrerequisitesTemplate?file_Id=${data}`,
            data: data,
            config: Config.configAuth(),
        });
        console.log('responseresponse', response)
        // const dataRes = await this.handleResponseForceEncripted(response);
        return response.data;
    }

    async UploadPrerequisitesBatchFile(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/UploadPrerequisitesBatchFile`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async RegisterPrerequisitesDocument(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/RegisterPrerequisitesDocument`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async DisablePrerequisitesDocument(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/DisablePrerequisitesDocument`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }

    async SkipPrerequisites(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/SkipPrerequisites`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }
    
    async ReorderParticipantMassive(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/ReorderParticipant`,
            data: data,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponseForceEncripted(response);
        return dataRes;
    }
}