import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  player: {
    float: "left",
    padding: "0.2em",
    backgroundColor: "black",
    border: "1px solid black",
    // borderRadius: '5px',
  },
  videoElement: {
    // border: '1px solid darkgreen',
    width: "100%",
    background: "black",
    height: "20vh",
  },
}));

export const VideoPlayerTextExpensive = (props) => {
  const classes = useStyles();
  const playerRef = React.useRef(null);
  const progressBar = props.ProgressBar;

  useEffect(() => {
    if (props.Playing) {
      playPauseVideo();
      playerRef.current.addEventListener(
        "timeupdate",
        updateProgressBar,
        false
      );
    }
  }, [props.Playing]);

  useEffect(() => {
    if (
      props.ProgressBar !== undefined &&
      props.ProgressBar !== null &&
      props.ProgressBar !== ""
    ) {
      progressBar.addEventListener("click", seek);
    }
  }, [props.ProgressBar]);

  useEffect(() => {
    if (props.setLengtVideo) {
      if (playerRef.current === null || playerRef.current === undefined) {
        return;
      }

      var timerCalculate = Math.floor((playerRef.current.duration / 1000) % 60);
      console.log("current.duration: ", timerCalculate);
      props.setLengtVideo(timerCalculate);
    }
  }, [props.Playing, props.setLengtVideo]);

  function playPauseVideo() {
    console.log("playerRef: ", playerRef.current);
    if (playerRef.current === null || playerRef.current === undefined) {
      return;
    }
    console.log("playerRef INIT: ", playerRef.current);
    // console.log("playerRef: ", playerRef)
    if (playerRef.current.paused || playerRef.current.ended) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  }

  function stopVideo() {
    if (playerRef.current === null || playerRef.current === undefined) {
      return;
    }
    playerRef.current.pause();
    if (playerRef.current?.currentTime) {
      playerRef.current.currentTime = 0;
    }
  }

  function seek(e) {
    try {
      var percent = e.offsetX / this.offsetWidth;
      playerRef.current.currentTime = percent * playerRef.current.duration;
      e.target.value = Math.floor(percent / 100);
      e.target.innerHTML = progressBar.value + "% played";
    } catch (e) {
      console.log("Advertencia: ", e);
    }
  }

  // Update the progress bar
  function updateProgressBar() {
    try {
      // Work out how much of the media has played via the duration and currentTime parameters
      var percentage = Math.floor(
        (100 / playerRef.current.duration) * playerRef.current.currentTime
      );
      // Update the progress bar's value
      progressBar.value = percentage;
      // Update the progress bar's text (for browsers that don't support the progress element)
      progressBar.innerHTML = percentage + "% played";
    } catch (e) {
      console.log("Advertencia: ", e);
    }
  }

  return (
    <div id="video-element" className={classes.player}>
      <video ref={playerRef} className={classes.videoElement}>
        <source src={props.src} type="video/mp4" />
      </video>
    </div>
  );
};
