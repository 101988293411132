import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class SignatureService {
  static instance;

  constructor() {
    this.baseUrl = "document";
  }

  static getInstance() {
    if (!SignatureService.instance) {
      SignatureService.instance = new SignatureService();
    }
    return SignatureService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async verifyCertificateStatus(data) {
    try {
      console.log('DATA VERIFICACION', data)
      const response = await GenericService.post({
        endpoint: `${this.baseUrl}/VerifyStatusCertificate`,
        data: Config.setParametersForceEncripted(data, false),
        config: Config.configAuth(),
      });
      console.log("RESPUESTAERRRO", response)
      const statusCer = await this.handleResponseForceEncripted(response);
      return statusCer;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }

  }

  async signDocument(data) {
    console.log('dataEnvio', data)
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/DigitalSignSATDocument`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  }



  // Verificar XML por medio de token
  async verifyXml(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/VerifySignFEA`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // Verificar xml sin token
  async verifySignFEAWithOutT(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/verifySignFEAWithOutT`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // Descargar xml con UID
  async downloadDocumentXmlByUid(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadDocumentXmlByUid`,
      data: data,
      // Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // Firma autografa
  async autographSignDocument(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/AutographSignDocument`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log(response)
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // La configuracion del PIN para validarlo
  async ValidatePINStageSing(data) {
    console.log("+++++++++++++PIN DE AUTORIZACION REQUEST--------+++++++++++******************: ", data)
    const response = await GenericService.post({
      endpoint: `validation/ValidatePINStageSing`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log(response)
    // const signature = await this.handleResponseForceEncripted(response);
    return response;
  };

  // API para poder enviar y reenviar el PIN
  async notificationPINStageFile(data) {
    console.log(data)
    const response = await GenericService.post({
      // endpoint: `notification/NotificationPINStageFile`,
      endpoint: `notification/NotificationPINStageSignFile`, //Se cambia ya que se usa para ambos flujos masivo e individual.
      data: data,
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // Para la informacion de evidencia
  async uploadEvidence(data) {
    const response = await GenericService.post({
      endpoint: `file/UploadEvidence`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const signature = await this.handleResponseForceEncripted(response);
    return signature;
  };

  // Validar la informacion de biometricos dentro del Texto
  async getBiometricStatus(data) {
    const response = await GenericService.post({
      endpoint: `biometric/GetBiometricStatus`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log(response);
    // const signature = await this.handleResponseForceEncripted(response);
    return response;
  };

  // Para poder cargar el video o contenido
  async uploadVideoEvidenceContent(data) {
    console.log('formData', data)

    var formData = new FormData();
    formData.append('DocumentSection_Id', data.DocumentSection_Id);
    formData.append('FileContent', data.B64Data, data.fileName);
    formData.append('IsMasive', data.IsMasive);
    formData.append('ContinueWorkFlow', data.ContinueWorkFlow);

    console.log('formData', formData)

    const response = await GenericService.post({
      endpoint: `file/UploadVideoEvidenceContentJsonResult`,
      data: formData,
      config: Config.configAuth(),
    });
    console.log('RESPONSEVIDEO', response.data.data)
    // const signature = await this.handleResponseForceEncripted(response);
    return response.data.data;
  };

  async getUserActionCustom(data) {
    const response = await GenericService.post({
      endpoint: `user/GetUserActionCustom`,
      data: Config.setParametersForceEncripted(data, false),
      // config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async validateCAPTCHA(data) {
    console.log(data)
    const response = await GenericService.post({
      endpoint: `validation/ValidateCAPTCHA`,
      data: data,
      // config: Config.configAuth(),
    });
    console.log('responseresponseresponse', response);
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }


  // API PARA PODER INICIAR EL SINCRONIZADO DE TOUCHPAD FIRMABLE
  async autographSignSync(data) {
    console.log(data)
    const response = await GenericService.post({
      endpoint: `document/AutographSignSync`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log(response);
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async autographSignSyncSave(data) {
    console.log(data)
    const response = await GenericService.post({
      endpoint: `document/AutographSignSyncSave`,
      data: data,
      // config: Config.configAuth(),
    });
    console.log(response);
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async autographSignSyncInfo(data) {
    console.log(data)
    const response = await GenericService.post({
      endpoint: `document/AutographSignSyncInfo`,
      data: data,
      config: Config.configAuth(),
    });
    console.log(response);
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  // async getFileQRSignSimple(data) {
  //   console.log('DATADEQR', data)
  //   const response = await GenericService.get({
  //     endpoint: `notification/GenerateQRImage?text=${data}`,
  //     config: Config.configDoc(),
  //   });
  //   return response;
  // }

  async postFileQRSignPost(data) {
    console.log('DATADEQR', data)
    const response = await GenericService.post({
      endpoint: `notification/GenerateQRImage2`,
      data: data,
      config: Config.configDoc(),
    });
    return response;
  }

}
