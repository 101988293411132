import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeConfig } from "./theme/ThemeConfig.jsx";
import storepersist from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Home } from "./pages/home/Home.jsx";
import LoginComponent from "./pages/login/LoginComponent.js";
import NewUserComponent from "./pages/login/newUser/NewUserComponent";
import ResetPasswordComponent from "./pages/profile/ressetPassword/ResetPasswordComponent";
import ConfirmRegister from "./pages/login/newUser/ConfirmRegister.js";
import { Inbox } from "./pages/inbox/Inbox.jsx";
import { LayoutLogin } from "./pages/home/LayoutLogin.jsx";
import { PrivateRoute } from "./components/PrivateRoute.jsx";
import { NewFile } from "./pages/new-file/NewFile.jsx";
import { NotFoundPage } from "./components/NotFoundPage.jsx";
import DocumentUploadXML from "./pages/flow/CheckXML/DocumentUploadXML.js";
import { UnAuthenticated } from "./pages/unauthenticated/UnAuthenticated.jsx";
import { CapturePreviousDocuments } from "./pages/CollaborativeEditing/CapturePreviousDocuments.jsx";
import CodeReader from "./pages/CodeReader/CodeReader.jsx";
import { ProfileTabs } from "./pages/profile/ProfielTabs.js";
import SecurityCode from "./pages/profile/ressetPassword/SecurityCode.js";
import RestartPassword from "./pages/login/restartPassword/RestartPassword.js";
import ScreenMovilPad from "./pages/flow/FirmaDocumento/StagesSignature/FirmaAutografa/ScreenMovilPad.js";
import packageJson from "../package.json";
import { clearCacheData } from "./utils"

const App = () => {
  const { store, persistor } = storepersist;

  useEffect(() => {
    localStorage.setItem('inicioApp', "inicio");
    caching()
  }, [])

  let caching = () => {
    let version = localStorage?.getItem('versionApp');
    // console.log("version: ", version)
    // console.log(" packageJson.version: ", packageJson.version)
    if (version != packageJson.version) {
      if ('caches' in window) {
        clearCacheData()
        // console.log("Ejecuto reload.")
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('versionApp', packageJson.version);
    }
  };

  useEffect(() => {
    const reloadPage = () => {
      const shouldRefresh = sessionStorage.getItem('refreshFlag');
      // console.log("shouldRefresh: ", shouldRefresh)
      if (shouldRefresh === 'true' || shouldRefresh === null || shouldRefresh === "null") {
        // console.log("shouldRefresh IF: ", shouldRefresh)
        sessionStorage.setItem('refreshFlag', 'false');
        clearCacheData();
        window.location.reload();
      } else {
        // console.log("shouldRefresh else: ", shouldRefresh)
        sessionStorage.setItem('refreshFlag', 'true');
      }
    };

    reloadPage();
  }, []);

  return (
    <BrowserRouter
      basename={
        process.env.NODE_ENV === "development"
          ? "/"
          : `${process.env.REACT_APP_BASENAME}`
      }
    >
      <Provider store={store}>
        <PersistGate loading="null" persistor={persistor}>
          <ThemeConfig>
            <Routes>
              <Route path="/auth" element={<LayoutLogin />}>
                <Route index element={<Navigate to="login" />} />
                <Route path="login" element={<LoginComponent />} />
                <Route path="login?uid=idUser" element={<LoginComponent />} />
                <Route path="login?ActionId=idStage" element={<LoginComponent />} />
                <Route path="login?Email=Email&FileId=FileId&IsCompleteRegister=IsCompleteRegister" element={<LoginComponent />} />
                <Route path="newUser" element={<NewUserComponent />} />
                <Route path="newUser?ActionId=idUser" element={<NewUserComponent />} />
                <Route path="verifity-xml" element={<DocumentUploadXML />} />
                <Route path="resetPassword" element={<ResetPasswordComponent />} />
                <Route path="resetPassword?mail=mail" element={<ResetPasswordComponent />} />
                <Route path="restartPassword" element={<RestartPassword />} />
                <Route path="confirmRegister" element={<ConfirmRegister />} />
                <Route path="securityCode" element={<SecurityCode />} />
              </Route>
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Navigate to="inbox" />} />
                <Route path="inbox" element={<Home />}>
                  <Route index element={<Navigate to="dashboards" />} />
                  <Route path="*" element={<Inbox />} />
                  <Route path="new-file" element={<NewFile />} />
                  <Route path="profileUser" element={<ProfileTabs />} />
                  <Route path="profileUser?tab=tab" element={<ProfileTabs />} />
                  <Route path="securityCode" element={<SecurityCode />} />
                </Route>
              </Route>
              <Route path="unAuthenticated" element={<UnAuthenticated />} />
              <Route path="unAuthenticated?ActionId=idStage&?view=idView" element={<UnAuthenticated />} />
              <Route path="unAuthenticated?email=Email&?FileId=fileId" element={<UnAuthenticated />} />
              <Route path="lector-codigo" element={<CodeReader />} />
              <Route path="touchPadSign" element={<ScreenMovilPad />} />
              <Route path="touchPadSign?GUID=id&UserId=id" element={<ScreenMovilPad />} />
              <Route path="CapturePrevDoc" element={<CapturePreviousDocuments />} />
              <Route path="*" element={<Navigate to="404" />} />
              <Route path="404" element={<NotFoundPage />} />
            </Routes>
          </ThemeConfig>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;