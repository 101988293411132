import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import {
  getFile,
  editStack,
  GetFilesCustomMasive,
  RegisterBatchUploadSignedProcess,
  UploadTemplateManager,
  GetTemplates,
  GetContentTemplate,
  GetTemplatesActive,
  GetCategoriesTemplate,
  resetTemplate,
} from "../../store/actions";
import { UTCtoLocal } from "../../utils";
import { AlertConfirm } from "../../components/Alert";
import { GenericTable } from "../../components/GenericTable";
import BackdropComponent from "../../components/BackDrop";
// import InboxSignatureResponsive from "./InboxSignatureResponsive";
import sign from "../../assets/sign.svg";

moment.locale("es");

const columns = [
  {
    Header: "Id",
    accessor: "TemplateId",
  },
  {
    Header: "Plantilla",
    accessor: "TemplateName",
  },
  {
    Header: "Categoría",
    accessor: "CategoryName",
  },
  {
    Header: "Fecha de creación",
    accessor: "CreationDate",
  },
  {
    Header: "Extension",
    accessor: "Extension",
  },
  {
    Header: "CategoryId",
    accessor: "CategoryId",
  },
  {
    Header: "Estatus",
    accessor: "Active",
  },
  {
    Header: "Fecha de modificacion",
    accessor: "LastModification",
  },
];

const Image = styled("img")(({ theme }) => ({
  width: 170,
  marginTop: 30,
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    width: 150,
  },
}));

export const TemplateManager = (props) => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [folioSelectedSignature, setFolioSelectedSignature] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDefaultDate = new Date();

  const UploadTemplateManagerState = useSelector(
    (state) => state.UploadTemplateManagerData
  );
  const GetTemplatesState = useSelector((state) => state.GetTemplatesData);
  const GetContentTemplateState = useSelector(
    (state) => state.GetContentTemplateData
  );
  const GetTemplatesActiveState = useSelector(
    (state) => state.GetTemplatesActiveData
  );
  const GetCategoriesTemplateState = useSelector(
    (state) => state.GetCategoriesTemplateData
  );

  const dispatch = useDispatch();
  const formMethods = useForm();

  useEffect(() => {
    dispatch(GetTemplates(0));
  }, []);

  //Efecto para mapear la data de la api guardada en redux para la tabla
  useEffect(() => {
    console.log("GetTemplatesState: ", GetTemplatesState);
    if (GetTemplatesState?.items) {
      const filesTable = GetTemplatesState?.items?.map((itemData) => {
        // console.log("itemData", itemData);
        return {
          TemplateId: itemData.Template_Id,
          TemplateName: itemData.FileName,
          CategoryName: itemData.CategoryName,
          CreationDate: moment(
            itemData.CreationDate ?? formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A"),
          Extension: itemData.Extension,
          CategoryId: itemData.Category_Id,
          Active: itemData.Active ? "Activo" : "Inactivo",
          LastModification: moment(
            itemData.LastModification ?? formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A"),
        };
      });
      setdata(filesTable);
    } else {
      setdata([]);
    }
  }, [GetTemplatesState]);

  useEffect(() => {
    formMethods.reset();
  }, [location.pathname, formMethods]);

  const renderTable = () => {
    return (
      <>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="subtitle1">
            Las solicitudes que se muestran a continuación pertenecen a una
            solicitud masiva, selecciona aquellas que quieras firmar en grupo
            (tu firma será replicada en las solicitudes seleccionadas)
          </Typography>
        </Box>
        {/* <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <InboxSignatureResponsive
            data={data}
            ClickSelectedRows={(data) => {
              console.log("ClickSelectedRows ---- --data------: ", data);
              var array = data.map((item) => {
                return item.invoice;
              }); // Se filtra solo el objeto original
              console.log("sleected: item: ", array);
              setFolioSelectedSignature(array);
            }}
          />
        </Box> */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {data?.length > 0 ? (
            <GenericTable
              Columns={columns}
              Data={data}
              onClickRow={(row) => {
                console.log("row: ", row);
                navigate("/inbox/TemplateUpload", {
                  state: row.original,
                });
              }}
              HiddenColumns={["TemplateId", "CategoryId", "LastModification"]}
              ShowPagination={false}
              ClickSelectedRows={(data) => {
                var array = data.map((item) => {
                  return item.original.invoice;
                }); // Se filtra solo el objeto original
                // console.log("sleected: item: ", array);
                setFolioSelectedSignature(array);
              }}
            />
          ) : null}
        </Box>
      </>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <BackdropComponent loading={loading} />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ mt: 1 }}>
          {GetTemplatesState?.loading ? (
            <Box sx={{ my: 10, display: "grid", placeContent: "center" }}>
              <CircularProgress
                size={60}
                sx={{ margin: "auto", display: "flex" }}
              />
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1">Buscando</Typography>
              </Box>
            </Box>
          ) : (
            (function () {
              if (data === undefined || data?.length === 0) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Image alt="firma" src={sign} />
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: 600, my: 2 }}
                      align="center"
                    >
                      Sin plantillas cargadas
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ maxWidth: 600, color: "secondary.main80" }}
                      align="center"
                    >
                      No tienes plantillas cargadas para tus flujos
                    </Typography>
                  </Box>
                );
              }
              return renderTable();
            })()
          )}
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ mt: 1, mb: 3 }}
        >
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: 45, px: 10 }}
              onClick={() => {
                dispatch(resetTemplate());
                navigate("/inbox/TemplateUpload");
              }}
            >
              Cargar nueva plantilla
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </FormProvider>
  );
};
