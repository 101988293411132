import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import { Box } from "@mui/system";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";
import { Icon } from "@iconify/react";
import {
  getDocument64,
  setUrlDocument64,
} from "../../store/actions/ExpedienteActions.js";
import BackdropComponent from "../../components/BackDrop";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { FileService } from "../../services/FileService.js";
import { CustomModalAlert } from "../../components/CustomModalAlert.jsx";
import { B64toBlob, b64toUrl } from "../../utils/Tools";
import { Alert } from "../../components/Alert.jsx";
import {
  getFile,
  SetDocumentValidation,
  ContinueWorkFlow,
  CancelWorkFlow,
} from "../../store/actions";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { WhiteTooltip } from "../../components/WhiteTooltip.jsx";

export const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

export const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export const ApprovalModule = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [documentSeletedCurrent, setDocumentSeletedCurrent] = useState([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [commentCurrent, setCommentCurrent] = useState("");
  const [openAlerReject, setOpenAlerReject] = useState(false);
  const [openAlerAcept, setOpenAlerAcept] = useState(false);
  const [aprovedDocument, setAprovedDocument] = useState(false);
  const [activeRegisterDocVal, setActiveRegisterDocVal] = useState(false);
  const [listFileDocuments, setListFileDocuments] = useState([]);

  const [openHelpFinalice, setOpenHelpFinalice] = useState(false);
  const [openHelpFinReject, setOpenHelpFinReject] = useState(false);
  const [wfContinueRequest, setWFContinueRequest] = useState(false);
  const [activeCancelFlow, setActiveCancelFlow] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(false);

  const [commentFlowRechazo, setCommentFlowRechazo] = useState("");
  const [openFlowAlertReject, setOpenFlowAlerReject] = useState(false);

  const fileState = useSelector((state) => state.File);
  const documentListPreview = useSelector(
    (state) => state.File?.item?.Documents
  );
  const SetDocValDataState = useSelector((state) => state.SetDocValidationData);
  const WorkFlowDataState = useSelector((state) => state.WorkFlowData);
  const listFileDocumentsState = useSelector(
    (state) =>
      state.File?.item?.FileData?.Actors[0]?.DocumentalGroups[0]?.Documents
  );
  const listDocPrerequisitos = useSelector(
    (state) =>
      state.File?.item?.FileData?.Stage?.Properties?.find(
        (item) => item.Name === "DocumentListName"
      )?.Value
  );
  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );
  const participantCurso =
    userData?.Username === fileState?.item?.FileData?.CurrentUser;

  useEffect(() => {
    console.log("SetDocValDataState********: ", SetDocValDataState);
    if (
      SetDocValDataState.items !== undefined &&
      SetDocValDataState.items !== null &&
      activeRegisterDocVal
    ) {
      console.log("SetDocValDataState****Entro****: ", SetDocValDataState);
      setOpenAlerReject(false);
      setOpenAlerAcept(false);
      // console.log("fileState?.item?.FileData?.Id: ", fileState?.item?.FileData?.Id)
      dispatch(getFile({ FileId: fileState?.item?.FileData?.Id }));
      if (aprovedDocument) {
        Alert({
          icon: "success",
          title: "El documento ha sido aprobado con éxito",
          // text: "Se le notificó al participante la validación del documento.",
        });
      } else {
        Alert({
          icon: "success",
          title: "El documento ha sido rechazado",
          // text: "Se le notificó al participante la validación del documento.",
        });
      }
    }
  }, [SetDocValDataState]);

  useEffect(() => {
    console.log("activeCancelFlow", activeCancelFlow);

    console.log(
      "WorkFlowDataState.items***********-------- ",
      WorkFlowDataState.items
    );
    if (WorkFlowDataState.items !== undefined && wfContinueRequest) {
      setWFContinueRequest(false);
      console.log("ENTRO AQUI 0");

      if (activeCancelFlow) {
        setActiveCancelFlow(false);
        let Body = {
          File_Id: fileState?.item?.FileData?.Id,
          Comments: commentFlowRechazo,
        };
        console.log("Cancela el flujo ENTRO: Request: *****", Body);
        dispatch(CancelWorkFlow(Body));
      }
      if (activeCancelFlow) {
        console.log("ENTRO AQUI 1");
        Alert({
          icon: "success",
          title: "Has rechazado la solicitud.",
        });
      }
      // else {
      //   console.log("ENTRO AQUI 2");
      //   Alert({
      //     icon: "success",
      //     title:
      //       "Le sera notificado al usuario para subir de nuevo los documentos",
      //   });
      // }
      navigate("/inbox/files");
      // getInfoDispatch()
    } else if (WorkFlowDataState.error !== undefined && wfContinueRequest) {
      setWFContinueRequest(false);
      console.log("WorkFlowDataState.error-------- ", WorkFlowDataState.error);
    }
  }, [WorkFlowDataState]);

  useEffect(() => {
    console.log(
      "listFileDocumentsState********: ",
      listFileDocumentsState,
      " - listFileDocumentsState - ",
      listFileDocumentsState[0],
      " - listFileDocumentsState?.length: ",
      listFileDocumentsState?.length
    );

    setBlobUrl(null);
    // var listDocsPrere = []
    console.log("listDocPrerequisitos********: ", listDocPrerequisitos);

    if (
      listDocPrerequisitos !== undefined &&
      listDocPrerequisitos !== null &&
      listDocPrerequisitos !== ""
    ) {
      var listProperties = JSON.parse(listDocPrerequisitos);
      console.log("listDocPrerequisitos*ENTRO*******: ", listProperties);
      let newData = listProperties
        ?.map((data) => {
          var documentFind = listFileDocumentsState.find(
            (item) => item.Name === data.DocumentName
          );
          return {
            ...documentFind,
            Name: data.DocumentName,
            Required: data.Required,
          };
        })
        .filter((item) => item.ValidationData !== undefined);
      console.log("newData: ", newData);
      setListFileDocuments(newData);
      selectedDocument(newData[0]);
    }
  }, [listFileDocumentsState, listDocPrerequisitos]);

  useAsyncEffect(async () => {
    console.log("documentSeletedCurrent: ", documentSeletedCurrent);
    if (
      documentSeletedCurrent !== undefined &&
      documentSeletedCurrent !== null
    ) {
      if (documentSeletedCurrent.Id) {
        setDocumentPreview(false);
      }
      setBlobUrl(null);
      console.log("documentSeletedCurrent", documentSeletedCurrent.Id);
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument({
        document_Id:
          documentSeletedCurrent?.Id ||
          documentSeletedCurrent?.DocumentData?.Id,
        ConvertSfdt: false,
      });
      dispatch(
        getDocument64({
          document_Id:
            documentSeletedCurrent?.Id ||
            documentSeletedCurrent?.DocumentData.Id,
          ConvertToWord: false,
        })
      );
      traerDocumentos(comentData);
    }
  }, [documentSeletedCurrent]);

  const traerDocumentos = (comentData) => {
    if (comentData.Body) {
      console.log("comentData.Body: ", comentData.Body);
      var extension =
        comentData.Body.FileName.split(".").length > 1
          ? comentData.Body.FileName.split(".")[1]
          : "pdf";
      console.log("comentData.Body: extension ", extension);
      if (extension === "docx") {
        const docConvertido = convertWordB64ToPdfB64(
          comentData?.Body?.B64Content
        );
        docConvertido.then((value) => {
          dispatch(setUrlDocument64(value));
          setBlobUrl(value);
        });
      } else {
        const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
        dispatch(setUrlDocument64(URL.createObjectURL(blob)));
        setBlobUrl(URL.createObjectURL(blob));
      }
    }
  };

  const selectedDocument = (item) => {
    console.log("selectedDocument******IMPORTANT: ", item);
    setDocumentSeletedCurrent(item);
  };

  let handleSendAprobe = (event) => {
    // console.log("documentSeletedCurrent: ", documentSeletedCurrent)
    setOpenAlerAcept(false);
    setOpenAlerReject(false);
    let request = {
      Body: {
        Document_Id: documentSeletedCurrent?.Id,
        Section_Id: documentSeletedCurrent?.Sections[0]?.Section_Id,
        Approved: aprovedDocument,
        Comments: commentCurrent,
        ValidateFileComplete: false,
      },
    };

    console.log("request:******* ", request);
    dispatch(SetDocumentValidation(request));
    setActiveRegisterDocVal(true);
  };

  const handleOneClickFinaliceProcess = (e) => {
    var pedingValidation = listFileDocuments.filter(
      (item) => item.ValidationData?.Approved === false
    );
    console.log("pedingValidation:****** ", pedingValidation);
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      setOpenHelpFinalice(true);
    }
  };

  const handleSendFinaliceProcess = (e) => {
    var pedingValidation = listFileDocuments.filter(
      (item) => item.ValidationData?.Approved === false
    );
    console.log("pedingValidation:****** ", pedingValidation);

    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      let Body = {
        File_Id: fileState?.item?.FileData?.Id,
        Status: "Completo",
        Result: "Completo",
      };
      console.log("Request: ContinueWorkFlow ", Body);
      dispatch(ContinueWorkFlow(Body));
      setWFContinueRequest(true);
      setOpenHelpFinalice(false);
      Alert({
        icon: "success",
        title:
          "Has aprobado todos los documentos, comienza tu proceso de revisión o firma",
      });
    }
  };

  const handleSendLeftFinalice = (e) => {
    let Body = {
      File_Id: fileState?.item?.FileData?.Id,
      Status: "Completo",
      Result: "Cancelado",
    };

    dispatch(ContinueWorkFlow(Body));
    setWFContinueRequest(true);
    console.log("Cancela el flujo****Body*", Body);
    setActiveCancelFlow(true);
    setOpenHelpFinReject(false);
  };

  const handleSendRightFinalice = (e) => {
    let Body = {
      File_Id: fileState?.item?.FileData?.Id,
      Status: "Completo",
      Result: "rechazado",
    };

    dispatch(ContinueWorkFlow(Body));
    console.log("Cancela el flujo****Body*", Body);
    setWFContinueRequest(true);
    setOpenHelpFinReject(false);
    Alert({
      icon: "success",
      title:
        "Le notificaremos al usuario que deberá cargar nuevamente los documentos.",
    });
  };

  return (
    <Fragment>
      <BackdropComponent loading={fileState.loading} />
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1, mb: 1 }}>
              <ButtonActionDocument
                documentPreview={false}
                SetDocumentPreview={setDocumentPreview}
                ListDocuments={listFileDocuments}
                selectedDocument={selectedDocument}
                documentSeleted={documentSeletedCurrent}
              />
              <ButtonActionDocument
                documentPreview={true}
                SetDocumentPreview={setDocumentPreview}
                ListDocuments={documentListPreview}
                selectedDocument={selectedDocument}
                documentSeleted={documentSeletedCurrent}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              {participantCurso === true ? (
                listFileDocuments?.filter(
                  (item) =>
                    (item.ValidationData?.Approved === undefined ||
                      item.ValidationData?.Approved === null) &&
                    item.Required === true
                )?.length === 0 ? (
                  <Grid
                    container
                    spacing={1}
                    justifyContent={{ xs: "center", md: "flex-start" }}
                  >
                    <Grid item xs={11} sm={10}>
                      <Box sx={{ display: "flex", textAlign: "justify" }}>
                        <Typography variant="subtitle1">
                          Los documentos rechazados (incorrectos) serán
                          solicitados nuevamente para su carga.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={11} sm={10}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ height: 45, px: 5 }}
                        onClick={handleOneClickFinaliceProcess}
                      >
                        Continuar
                      </Button>
                    </Grid>
                    <Grid item xs={11} sm={10}>
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{ height: 45, px: 5 }}
                        onClick={() => {
                          setCommentFlowRechazo("");
                          setOpenFlowAlerReject(true);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={9}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ border: "1px solid #E4E4E4", p: 2, mt: 2, borderRadius: 2 }}
          >
            <Grid item>
              <Box sx={{ mt: 2, minHeight: 300 }}>
                {blobUrl ? (
                  <VisorPDF
                    pdfUrl={blobUrl ?? ""}
                    pdfName={fileState?.document_64?.FileName ?? ""}
                  />
                ) : listFileDocuments.length > 0 ? (
                  <Spinner />
                ) : (
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Sin documentos por validar.
                  </Typography>
                )}
              </Box>
            </Grid>
            {documentSeletedCurrent === null ||
            documentSeletedCurrent?.ValidationData === null ||
            documentSeletedCurrent?.ValidationData?.Approved === true ||
            documentSeletedCurrent?.ValidationData?.Approved === false ||
            participantCurso === false ? null : documentPreview ===
              true ? null : (
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={11} sm={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ height: 45, px: 10 }}
                    onClick={() => {
                      setOpenAlerReject(true);
                      setCommentCurrent("");
                      setAprovedDocument(false);
                    }}
                  >
                    Rechazar
                  </Button>
                </Grid>

                <Grid item xs={11} sm={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: 45, px: 10 }}
                    onClick={() => {
                      setOpenAlerAcept(true);
                      setAprovedDocument(true);
                    }}
                  >
                    Aprobar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <CustomModalAlert
        OnClose={() => {
          setOpenAlerReject(false);
          setCommentCurrent("");
        }}
        Open={openAlerReject}
        Title="Rechazar Documento"
        SubTitle="Especifica el motivo de rechazo del documento."
        LeftButtonText="Cancelar"
        LeftButtonAction={() => {
          setOpenAlerReject(false);
          setCommentCurrent("");
        }}
        RightButtonText="Aceptar"
        Comments={true}
        ValueComment={commentCurrent}
        CommentPlaceHolder="Motivo de rechazo"
        setValueComment={setCommentCurrent}
        RightButtonAction={handleSendAprobe}
      />

      <CustomModalAlert
        OnClose={() => {
          setOpenAlerAcept(false);
        }}
        Open={openAlerAcept}
        Title="Aprobación"
        SubTitle="¿Estás seguro que deseas aprobar el documento?"
        LeftButtonText="Rechazar"
        LeftButtonAction={() => {
          setOpenAlerAcept(false);
        }}
        RightButtonText="Aceptar"
        RightButtonAction={handleSendAprobe}
      />

      <CustomModalAlert
        OnClose={() => {
          setOpenHelpFinalice(false);
        }}
        Open={openHelpFinalice}
        Title="Finalizar validación"
        SubTitle="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
        LeftButtonText="Cancelar"
        LeftButtonAction={() => {
          setOpenHelpFinalice(false);
        }}
        RightButtonText="Aceptar"
        RightButtonAction={handleSendFinaliceProcess}
      />

      <CustomModalAlert
        OnClose={() => {
          setOpenHelpFinReject(false);
        }}
        Open={openHelpFinReject}
        Title="Finalizar validación"
        SubTitle="¿Estás seguro de volver a solicitar los documentos rechazados?"
        LeftButtonText="Cancelar"
        LeftButtonAction={() => {
          setOpenHelpFinReject(false);
        }}
        RightButtonText="Aceptar"
        RightButtonAction={handleSendRightFinalice}
      />

      <CustomModalAlert
        OnClose={() => {
          setOpenFlowAlerReject(false);
          setCommentFlowRechazo("");
        }}
        Open={openFlowAlertReject}
        Title="Rechazar solicitud"
        SubTitle="Especifica el motivo de rechazo de la solicitud."
        LeftButtonText="Cancelar"
        LeftButtonAction={() => {
          setOpenFlowAlerReject(false);
          setCommentFlowRechazo("");
        }}
        RightButtonText="Aceptar"
        Comments={true}
        ValueComment={commentFlowRechazo}
        CommentPlaceHolder="Motivo de rechazo"
        setValueComment={setCommentFlowRechazo}
        RightButtonAction={handleSendLeftFinalice}
      />
    </Fragment>
  );
};

const ButtonActionDocument = (props) => {
  const active = (item) => {
    // console.log("props?.documentSeleted?.Id: ", props?.documentSeleted?.Id)
    return props?.documentSeleted?.Id === item?.Id;
  };

  console.log(props.ListDocuments);

  return (
    <>
      <Box sx={{ mt: 1.5, mb: 1 }}>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          {props.documentPreview
            ? "Documentos a editar y/o firmar"
            : "Documentos por aprobar"}
        </Typography>
      </Box>
      {props.ListDocuments?.map((item, index) => (
        <ButtonAS
          SetDocumentPreview={props.SetDocumentPreview}
          documentPreview={props.documentPreview}
          item={item}
          active={active}
          index={index}
          selectedDocument={props.selectedDocument}
        />
      ))}
      {props.documentPreview ? (
        <Box sx={{ mt: 1, width: "80%" }}>
          <Typography variant="body2" textAlign={"justify"}>
            Estos documentos son los que pasarán por el proceso de edición y/o
            firma, <b>no se podrán modificar en esta sección,</b> solo podrán
            visualizarse.
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

const ButtonAS = ({
  documentPreview,
  item,
  active,
  index,
  selectedDocument,
  SetDocumentPreview,
}) => {
  let stateAprovatte = item?.ValidationData?.Approved;

  return (
    <Button
      sx={(theme) => ({
        color: theme.palette.secondary.dark,
        maxHeight: 70,
        background:
          documentPreview === true
            ? "#FFFFFF"
            : stateAprovatte === true && active(item) === true
            ? "#DEFBD4"
            : stateAprovatte === false && active(item) === true
            ? "#f4d6df"
            : active(item)
            ? "#F2F2F2"
            : stateAprovatte === true
            ? "#DEFBD4"
            : "#F7F8FA",
        width: "80%",
        textOverflow: "ellipsis",
        overflow: "hidden",

        border:
          documentPreview === true
            ? "1.6px solid #000000"
            : stateAprovatte === true && active(item) === true
            ? "1.6px solid #4B983E"
            : stateAprovatte === false
            ? "1.6px solid #C20E30"
            : active(item)
            ? "1.6px solid #848484"
            : "",
        margin: "0px",
        padding: "0px",
        mb: 1,
        "&:hover": {
          background: active(item)
            ? "#F2F2F2"
            : stateAprovatte === true
            ? "#DEFBD4"
            : stateAprovatte === true && active(item) === true
            ? "#DEFBD4"
            : stateAprovatte === false
            ? "#f4d6df"
            : "#F7F8FA",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      })}
      onClick={() => {
        selectedDocument(item);
        SetDocumentPreview(documentPreview);
      }}
    >
      <div style={{ width: "100%", height: 70 }}>
        <Box sx={{ display: "flex", alignItems: "center", height: 70 }}>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Box
              sx={{
                height: 70,
                mr: 1,
                color: "#FFFFFF",
                borderRight:
                  documentPreview === true ? "1.6px solid #000000" : null,
                background:
                  documentPreview === true
                    ? "#FFFFFF"
                    : stateAprovatte === true && active(item) === true
                    ? "#4CAF50"
                    : stateAprovatte === false && active(item) === true
                    ? "#C20E30"
                    : active(item)
                    ? "#848484"
                    : stateAprovatte === true
                    ? "#4CAF50"
                    : stateAprovatte === false
                    ? "#E3072D"
                    : "#D1D1D1",
              }}
            >
              <spam
                style={{
                  display: "flex",
                  width: "35px",
                  height: "100%",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  minHeight: "50px",
                  marginLeft: "8px",
                }}
              >
                {documentPreview === true ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      icon="system-uicons:document-stack"
                      style={{
                        mr: 2,
                        color: "#C20E30",
                        fontSize: 33,
                      }}
                    />
                  </Box>
                ) : stateAprovatte === true ? (
                  <CheckCircleOutlinedIcon />
                ) : stateAprovatte === false ? (
                  <ErrorOutlineOutlinedIcon />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 1,
                      fontSize: 16,
                    }}
                  >
                    {index + 1}
                  </Box>
                )}
              </spam>
            </Box>
            <WhiteTooltip
              placement="top"
              title={documentPreview ? item.FileName : item.Name}
            >
              <Box
                sx={{
                  flexGrow: 8,
                  ml: 1,
                  maxHeight: 60,
                  display: "flex",
                  textAlign: "left",
                }}
              >
                <Typography
                  textAlign="left"
                  sx={(theme) => ({
                    fontSize: "14px !important",
                    color: theme.palette.secondary.dark,
                  })}
                >
                  {documentPreview
                    ? item.FileName
                    : `${item.Name} ${
                        stateAprovatte === true
                          ? " - Aprobado"
                          : stateAprovatte === false
                          ? " - Rechazado"
                          : ""
                      }`}
                </Typography>
              </Box>
            </WhiteTooltip>
          </Box>
        </Box>
      </div>
    </Button>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
