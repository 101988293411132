import crypto from "crypto-browserify";

//Configuration
const defaultEncoding = "base64";
const algorithm = "aes-256-cbc";
const key = crypto.randomBytes(32);
const initVector = crypto.randomBytes(16);

//TODO RSA Public & private keys
const prPublicKeyDSC =
  "<RSAKeyValue><Modulus>tJl610zc4G3zYkQR5IWkxA3zmVBd3+6ix5jZ73j5Y26RR5tyGj4hJlSGc30DddXA0O8T9EIg94OK6DGv7+R5q3n6QofEHLN9itG8fNFNjUBJpEwAs8VW7iM5OHY7TdT2SCQcgYhomF6AiIjyXm4gvuy1Xb/srEhnFw8iuQ6QLJ/3dvoY7R0UaHldjoRFDWgYqv7NpmqR3Wda02fu4KruTHv3jRF47v8AtHtLJM8bzSe3IajXcJVjB2EjExlRZcDbDFHaFJ6Gt2spuKHPnUKioBxOeNv+gHK8DNCGr/XtWD46yNBdXqT9cFWMEiaknwq38Ifo5wxw7SstHbnZbKuShQ==</Modulus><Exponent>AQAB</Exponent></RSAKeyValue>";
const prPublicKey =
  "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr/XlaQZTO7Bd236nhxbbIWNFPdMiufK9TjSZfpHSpL8p+YEzn35c1A5tW840m9u4gh0x+H25cu6W4U/J2a5v91bQq1tsbnM96xtEfPzznwaE/Rp6zLu35Pq9E+SgXHR9Ua/2SYbHIrQMZP3P4P45wmryIlzGF55+Kt3UU04UrbZAW3LpTgFchrTXgl67mH3zDMQ93UcyOh+MlPTk9nHwR5og9KOBHRBpMkfc1QURAwLeabPQjDbhDWPiitRfn/0EyPwwZ+1CpXffA6Yx8L/5rpHIzNmeMqouz8hIsjKds+BMAZUeafKWxhtdL1ngfoekGJGIv53fMmcg1xXXEBhaTQIDAQAB-----END PUBLIC KEY-----";
const prPrivateKey =
  "-----BEGIN PRIVATE KEY-----MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC0mXrXTNzgbfNiRBHkhaTEDfOZUF3f7qLHmNnvePljbpFHm3IaPiEmVIZzfQN11cDQ7xP0QiD3g4roMa/v5HmrefpCh8Qcs32K0bx80U2NQEmkTACzxVbuIzk4djtN1PZIJByBiGiYXoCIiPJebiC+7LVdv+ysSGcXDyK5DpAsn/d2+hjtHRRoeV2OhEUNaBiq/s2mapHdZ1rTZ+7gqu5Me/eNEXju/wC0e0skzxvNJ7chqNdwlWMHYSMTGVFlwNsMUdoUnoa3aym4oc+dQqKgHE542/6AcrwM0Iav9e1YPjrI0F1epP1wVYwSJqSfCrfwh+jnDHDtKy0dudlsq5KFAgMBAAECggEAO4oV64EVmI/nG1Bhrbs5lMEcvEC4kij0VWVZBBAX9+Xt03O17FzrRN287bobN+/ef4MySua6Q5+EXgRzgl0Kl7PBA8oKp4OtMeTvhR7juFuna5j66kl23U+Tf7/K0WASSbpOA45bHVCag2jSrrsT5J3ZNS0e87Vy9zEwO15Hvc35J9/bhEkpGtHM4u2RfuJT0Lkmf5WrhDUCYNgQlxDqCxNw/qaEOrvUW5Pf+jEUMJQpSJkVGy8BgklxQQucgU3pET7xgUVeyBQV1bBAboavReIRPEtJCa375rJT12atR8OJU+sIHwjBE2+ypvd89LPFgqcfl0HNfk9PnNZt/3vBCQKBgQDivYpXiuz8QuWjPge+dz0fbwl22Qib9HMz+7PaCrd4RaVpG0cJOSH4DABqbWkh1lSbflesknC3zOzQzU2wg6+ugwGFsC/MBQGtVAtNcVoqeq7/Y/bo+CDFVh6TfC1iljcsfpiqSayLRGQ5GH5KMsIET8QsIXc8MXqRE/Xt9ggGRwKBgQDL56hZ8aRoMiZGNqzEnsuz7m8CYmlvkow7oG++33WdvkKLGiQSeJsMUIN2ID7fJKCEKC63ILsXCPCYMnLFAqVM3odMTx5TB07+WcHfbyW0fvx5QhpQ1cAQxb113xVuXRFyJ/KA5AB6XlxpDZXSrwGnuhzuMxIayJJTVWfw/GNq0wKBgBaKRx1qxyJQuClhOj3Z9q6QSD2TV8LBK06on/l2BUUkj0wClS46isDw2H28AK6HcwO+LJMQP7pV2djr/+QjQyyzA0y59hZvDPqhFzqMoS0F4y2qT1pbiE51wDZjKtPZIQUAVMbg6hEyLFBsuelAYH8zOso0TXkjZg7UECemxKdzAoGAR0k2EjvovoTJ3MJApHzfuoghRzQgqGETpcTxTEnTVNKvcPIyqkJ/8k4zp5ieYmTruUhTDLLktajqdEsu1LGYGMezhXojn+NuOn4myXaSOAgtPDdf++K+wx5enlbfpzXiY4aTwXv0rtNOe2vEdWO5sGngs2YnbjWw8nFpZVbXawECgYBkyG4wI58dXH8K1ERnf2vQXML0BUbWRa2DMYBICljm7cCqqK7AQq66RGPEghtpsBrOUQ1vqMijAcYRUP0rzotOyWzAkt4oSE3zFSWVz87Bh8teQZilkVMNykHmjbgIUhD/CGJXbHfc3/8TIi0H60AlA/gW0tDfQ9jQhU0ZFyS9xw==-----END PRIVATE KEY-----";

export function prepareRequest(data, encryptRequest) {
  if (encryptRequest) {
    if (typeof data === "object") data = JSON.stringify(data);

    try {
      //Creating nested SecurityData object
      let encdata = {
        KeyB64: key.toString(defaultEncoding),
        IVB64: initVector.toString(defaultEncoding),
      };

      let bodyEncript = CifrarInfo(data);
      let encKeys = generateSecurityData(encdata);
      let pkeyEncript = CifrarInfo(prPublicKeyDSC);

      //Creating encrypted object request
      let objRequest = {
        EncryptedBody: bodyEncript,
        SecurityData: encKeys,
        PKey: pkeyEncript,
      };
      // console.log("Informacion cifrada-------------: ", objRequest)
      return objRequest;
    } catch (error) {
      console.log("Error: (AES encrypt error) - " + error);
      return null;
    }
  } else {
    let objRequest = { Body: data };
    return objRequest;
  }
}

function CifrarInfo(data) {
  let cipher = crypto.createCipheriv(algorithm, key, initVector);
  let encoded = cipher.update(data, "utf8", defaultEncoding);
  encoded += cipher.final(defaultEncoding);

  return encoded;
}

function generateSecurityData(obj) {
  try {
    let buffer = new Buffer(JSON.stringify(obj));
    let encrypted = crypto
      .publicEncrypt(prPublicKey, buffer)
      .toString(defaultEncoding);

    return encrypted;
  } catch (error) {
    console.log("Error: (Encrypt RSA error) - " + error);
    return null;
  }
}

function decryptBody(keys, data) {
  let dataKeys = JSON.parse(keys);
  let keyB64 = dataKeys.KeyB64;
  let ivB64 = dataKeys.IVB64;

  try {
    let iv = Buffer.from(ivB64, defaultEncoding);
    let encryptedText = Buffer.from(data, defaultEncoding);
    let decipher = crypto.createDecipheriv(
      algorithm,
      Buffer.from(keyB64, defaultEncoding),
      iv
    );
    let decrypted = decipher.update(encryptedText);

    decrypted = Buffer.concat([decrypted, decipher.final()]).toString("utf-8");

    return decrypted;
  } catch (error) {
    console.log("Error: (Decrypt AES error) - " + error);
    return null;
  }
}

export function processRequest(data) {
  // console.log("Entri en if desencripta force------data.EncryptedBody----- ", data.EncryptedBody)
  if (data.EncryptedBody) {
    // console.log("Entri en if desencripta force------data.EncryptedBody----- ")
    try {
      let buffer = Buffer.from(data.SecurityData, defaultEncoding);
      let decrypted = crypto
        .privateDecrypt(
          { key: prPrivateKey, padding: crypto.constants.RSA },
          buffer
        )
        .toString();

      let response = {
        Body: JSON.parse(decryptBody(decrypted, data.EncryptedBody)),
        IsOK: data.IsOK,
        Messages: data.Messages,
      };
      // console.log("Decripted----DATA---response-----: ", response)
      return response;
    } catch (error) {
      console.error("Error: (Decrypt RSA error) - " + error);
    }
  } else if (data.Body) {
    return data;
  } else if (data.Body === undefined || data.Body === null) {
    return data;
  } else return data;
}
