import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  styled,
  AccordionDetails as AccDet,
  Accordion as Acc,
  AccordionSummary as AccSum,
  Typography,
} from "@mui/material";
import { List, arrayMove } from "react-movable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputField } from "../../components/InputField";
import { useForm } from "react-hook-form";
import { FileService } from "../../services/FileService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  setlistCheckers,
  setlistSigners,
  ReorderParticipant,
} from "../../store/actions";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import iconoMenu from "../../assets/icons/Dragable.svg";
import { UTCtoLocal } from "../../utils";
import { ToastNotification } from "../../components/toastNotification";
import IconToolTip from "../../components/IconToolTip";

const Accordion = styled(Acc)(({ theme }) => ({
  // height: 35,
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  padding: 0,
  // height: '40px !important',
  minHeight: 37,
  "& .MuiAccordionSummary-content": {
    margin: 0,
    // minHeight: 38,
  },
}));

const AccordionDetails = styled(AccDet)(({ theme }) => ({
  padding: 0,
}));

export const ParticipantDraggableTableResponsive = (propsComponent) => {
  const [editing, setediting] = useState(null);
  const listSigners = useSelector((state) => state.NewFile.participants.sign);
  const [listTypeUpdateOrder, setListTypeUpdateOrder] = useState("");
  const [userIdOrder, setUserIdOrder] = useState(null);
  const [newOrderCurent, setNewOrderCurent] = useState(null);
  const [loadUpdateOrder, setLoadUpdateOrder] = useState(false);

  const { control, watch, setValue } = useForm();
  const watchDateEdit = watch("dateEdit");

  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const typeSing = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
  );
  const typeSignFile = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  const reorderPartData = useSelector((state) => state.ReorderParticipantData);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("listTypeUpdateOrder,userIdOrder,newOrderCurent: ", listTypeUpdateOrder, userIdOrder, newOrderCurent)
    if (
      listTypeUpdateOrder !== null &&
      listTypeUpdateOrder !== "" &&
      userIdOrder !== null &&
      userIdOrder !== "" &&
      newOrderCurent !== null &&
      newOrderCurent !== ""
    ) {
      // console.log("newFileState.file.Id: ", newFileState.file.Id)

      var ordType = listTypeUpdateOrder === "checkers" ? "check" : "sign";
      console.log(
        "Entro a la peticion dispatch:listTypeUpdateOrder,userIdOrder,newOrderCurent: ",
        ordType,
        listTypeUpdateOrder,
        userIdOrder,
        newOrderCurent,
        newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id
      );
      const request = {
        File_Id: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        User_Id: userIdOrder,
        OrderType: ordType,
        NewOrder: newOrderCurent,
        CleanDate: false,
      };
      console.log(".:ReorderParticipant:. request: ", request);
      dispatch(ReorderParticipant(request));
      // console.log(".:ReorderParticipant:. despues de la peticion")
      setLoadUpdateOrder(true);
      setUserIdOrder(null);
      setNewOrderCurent(null);
    }
  }, [listTypeUpdateOrder, userIdOrder, newOrderCurent]);

  useEffect(() => {
    if (
      loadUpdateOrder === true &&
      reorderPartData.items !== undefined &&
      reorderPartData.items !== null
    ) {
      setLoadUpdateOrder(false);
      console.log("reorderPartData: ", reorderPartData);
      // propsComponent.setData(newData)
      console.log("listTypeUpdateOrder******: ", listTypeUpdateOrder);
      if (listTypeUpdateOrder === "checkers") {
        let newData = reorderPartData.items
          ?.filter((item) => item.Check !== null)
          ?.map((data) => {
            return {
              Check: data.Check,
              CheckDeadline: data.CheckDeadline,
              CheckOrder: data.CheckOrder,
              UserId: data.UserId,
              UserEmail: data.Email,
            };
          });
        console.log("newData: checkers", newData);
        dispatch(setlistCheckers(newData));
        setListTypeUpdateOrder("");
      } else if (listTypeUpdateOrder === "signers") {
        let newData1 = reorderPartData.items
          ?.filter((item) => item.Sign !== null)
          ?.map((data) => {
            return {
              ApplyNOM151: true,
              Sign: data.Sign,
              SignDeadline: data.SignDeadline,
              SignOrder: data.SignOrder,
              UserEmail: data.Email,
              UserId: data.UserId,
            };
          });
        console.log("newData1: signers", newData1);
        dispatch(setlistSigners(newData1));
        setListTypeUpdateOrder("");
      }
    }
  }, [reorderPartData]);

  const renderDate = (value, type) => {
    if (value.Check && value.CheckDeadline && type === "checkers") {
      return UTCtoLocal(value.CheckDeadline, false);
      // return value.CheckDeadline.replace('T',' ').replace('.000Z','');
    }
    if (value.Sign && value.SignDeadline && type === "signers") {
      return UTCtoLocal(value.SignDeadline, false);
      // return value.SignDeadline.replace('T',' ').replace('.000Z','');
    }
    return "-";
  };

  const handleEdit = (value) => {
    console.log("value*******: ", value);
    if (value?.Check === true && value?.CheckDeadline !== null) {
      // console.log("value?.CheckDeadline****: ", value?.CheckDeadline)
      const limDate = new Date(value?.CheckDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", new Date(lmd2));
    } else if (value?.Sign === true && value?.SignDeadline !== null) {
      // console.log("value?.SignDeadline****: ", value?.SignDeadline)
      const limDate = new Date(value?.SignDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", new Date(lmd2));
    }
    setediting(value);
  };

  const updatedate = async (newdate) => {
    if (newdate === undefined || newdate === null || newdate === "") {
      console.log("Fecha update null: ", newdate);
      return;
    }
    console.log("Actualiza fecha: ", newdate.toISOString());
    try {
      const fileService = FileService.getInstance();
      const participant = {
        ...editing,
        ...(editing && {
          CheckDeadline: newdate.toISOString(),
          [editing.Reviewer === true ? "ReviewerDeadLine" : ""]:
            newdate.toISOString(),
        }),
      };

      await fileService.registerParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        Participant: participant,
      });
      if (editing) {
        if (editing.Check || editing.Reviewer) {
          const newCheckList = newFileState.participants.check.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistCheckers(newCheckList));
        }
        if (editing.Sign) {
          const newSignList = newFileState.participants.sign.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistSigners(newSignList));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setediting(null);
    }
  };

  const cancelEdit = () => {
    setediting(null);
  };

  const handleValidateDate = (typeDate, key) => {
    try {
      const ValidityDate = newFileState.editStack
        ? fileState.item?.Validity
        : newFileState.file?.fileForm?.FileConfiguration?.Validity;
      const listchecks =
        propsComponent.listType === "checkers"
          ? propsComponent.data?.filter((l) => l.CheckDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.CheckDeadline);

      const lclength = listchecks.length;
      if (typeDate === "checkers") {
        if (lclength === 0) {
          return new Date();
        }

        const limDate = new Date();
        var dateCalcule = key <= 0 ? limDate.getDate() : null;
        console.log("RETORNO", limDate.setDate(dateCalcule));
        return limDate.setDate(dateCalcule);
      }
    } catch (ex) {
      console.log(".:handleValidateDate:. Error: ", ex);
    }
  };

  const handleValidateDateMax = (typeDate, key) => {
    try {
      const ValidityDate = newFileState.editStack
        ? fileState.item?.Validity
        : newFileState.file?.fileForm?.FileConfiguration?.Validity;
      const listsigns =
        propsComponent.listType === "signers"
          ? propsComponent.data?.filter((l) => l.SignDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.SignDeadline);

      if (typeDate === "checkers" && listsigns?.length > 0) {
        console.log("ENTRO AQUI REVISORES");
        var lastdatesign = listsigns[0].SignDeadline;

        const limDate = new Date(lastdatesign);
        return limDate.setDate(limDate.getDate());
      }

      // const limDate = new Date(ValidityDate);
      return null;
    } catch (ex) {
      console.log(".::. Error: ", ex);
    }
  };

  const handleDelete = async (user, list) => {
    const fileService = FileService.getInstance();
    try {
      const response = await fileService.removeParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        UserId: user.UserId,
        Action: "sign",
      });
      console.log("response ELIMINAR", response);
      ToastNotification({
        icon: "success",
        text: "Participante eliminado",
      });

      // setUserIdOrder(user.UserId);
      // setNewOrderCurent(newIndex + 1);
      // setListTypeUpdateOrder(propsComponent.listType);

      if (list === "signers") {
        const newList = listSigners.filter((el) => {
          /* if (!el.UserId) {
          return el.UserEmail !== user.UserEmail;
        } */
          return el.UserId !== user.UserId;
        });
        console.log(newList);
        dispatch(setlistSigners(newList));
      }
      /*  if (list === "viewers") {
      const newList = listViewers.filter((el) => el.UserId !== user.UserId);
      dispatch(setlistViewers(newList));
    } */
    } catch (err) {
      console.log(err);
      ToastNotification({
        icon: "error",
        text: "Inténtalo de nuevo",
      });
    }
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <List
        values={propsComponent.data}
        onChange={({ oldIndex, newIndex }) => {
          var varUserId = propsComponent.data[oldIndex]?.UserId;
          setUserIdOrder(varUserId);
          setNewOrderCurent(newIndex + 1);
          setListTypeUpdateOrder(propsComponent.listType);

          console.log(
            "*************: userId ",
            varUserId,
            " - New index: ",
            newIndex,
            " - propsComponent.data: 1: ",
            propsComponent.data
          );
          var newArrayMove = arrayMove(propsComponent.data, oldIndex, newIndex);

          console.log("newArrayMove: ", newArrayMove);
        }}
        renderList={({ children, props }) => <Box {...props}>{children}</Box>}
        renderItem={({ value, props, isDragged }) => {
          const rowcontent = (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ pointerEvents: "auto" }}
              >
                <Typography
                  variant="subtitle"
                  sx={{ mr: 1, ml: 1, fontWeight: 450 }}
                >
                  <span>{isNaN(props.key + 1) ? "x" : props.key + 1}</span>
                </Typography>
                <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
                  {value.UserEmail || value.Email}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    p: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <img
                          src={iconoMenu}
                          style={{ width: 17, marginRight: 5 }}
                          alt="imagen"
                        />
                      </Box>
                      <Box>
                        <Box>
                          <Typography
                            variant="subtitle"
                            sx={{ fontWeight: 450 }}
                          >
                            Fecha limite
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1.3, mb: 0.5 }}>
                          <Typography
                            variant="subtitle"
                            sx={{ fontWeight: 450 }}
                          >
                            Función
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1.3 }}>
                          <Typography
                            variant="subtitle"
                            sx={{ fontWeight: 450 }}
                          >
                            Acciones
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {value?.UserId === editing?.UserId ? (
                          <InputField
                            inputType="date"
                            control={control}
                            name="dateEdit"
                            sxInput={{ maxWidth: 200 }}
                            onSelect={updatedate}
                            minDate={handleValidateDate(
                              propsComponent.listType,
                              props.key
                            )}
                            maxDate={handleValidateDateMax(
                              propsComponent.listType,
                              props.key
                            )}
                          />
                        ) : (
                          <span>
                            {renderDate(value, propsComponent.listType)}
                          </span>
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1.3, mb: 0.5 }}>
                      {typeSing?.SignatureType !== null ||
                      typeSignFile?.SignatureType !== null ? (
                        typeSing?.SignatureType === "Simple" ||
                        typeSignFile?.SignatureType === "Simple" ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconToolTip
                              icon={true}
                              src={"teenyicons:otp-outline"}
                              title={"Contraseña de un solo uso."}
                              width={19}
                            />
                            <IconToolTip
                              src={"SignSimple.png"}
                              title={"Firma Autógrafa en Mensaje de Datos."}
                              width={24}
                            />
                            <IconToolTip
                              src={
                                typeSignFile?.VideoRecording ||
                                typeSing?.VideoRecording
                                  ? "VideoIcon.svg"
                                  : "VideoIconDisabled.svg"
                              }
                              title={"Videograbación de consentimiento."}
                              width={24}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconToolTip
                              icon={true}
                              src={"teenyicons:otp-outline"}
                              title={"Contraseña de un solo uso."}
                              width={19}
                              iconColor={
                                typeSignFile?.OTP || typeSing?.OTP
                                  ? "#D65E74"
                                  : "#979797"
                              }
                            />
                            <IconToolTip
                              src={"SignAvanzada.png"}
                              title={"Firma Electrónica Avanzada."}
                              width={24}
                            />
                            <IconToolTip
                              src={
                                typeSignFile?.VideoRecording ||
                                typeSing?.VideoRecording
                                  ? "VideoIcon.svg"
                                  : "VideoIconDisabled.svg"
                              }
                              title={"Videograbación de consentimiento."}
                              width={24}
                            />
                          </Box>
                        )
                      ) : value?.SignatureOptions?.SignatureType ===
                        "Simple" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconToolTip
                            icon={true}
                            src={"teenyicons:otp-outline"}
                            title={"Contraseña de un solo uso."}
                            width={19}
                          />
                          <IconToolTip
                            src={"SignSimple.png"}
                            title={"Firma Autógrafa en Mensaje de Datos."}
                            width={24}
                          />
                          <IconToolTip
                            src={
                              value?.SignatureOptions?.VideoRecording
                                ? "VideoIcon.svg"
                                : "VideoIconDisabled.svg"
                            }
                            title={"Videograbación de consentimiento."}
                            width={24}
                          />
                        </Box>
                      ) : value?.SignatureOptions?.SignatureType ===
                        "Avanzada" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconToolTip
                            icon={true}
                            iconColor={
                              value?.SignatureOptions?.OTP
                                ? "#D65E74"
                                : "#979797"
                            }
                            src={"teenyicons:otp-outline"}
                            title={"Contraseña de un solo uso."}
                            width={19}
                          />
                          <IconToolTip
                            src={"SignAvanzada.png"}
                            title={"Firma Electrónica Avanzada."}
                            width={22}
                          />
                          <IconToolTip
                            src={
                              value?.SignatureOptions?.VideoRecording
                                ? "VideoIcon.svg"
                                : "VideoIconDisabled.svg"
                            }
                            title={"Videograbación de consentimiento."}
                            width={24}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{ pointerEvents: "auto" }}
                        size="small"
                        color="secondary"
                        onClick={
                          (e) =>
                            value.UserId === editing?.UserId
                              ? cancelEdit()
                              : handleEdit(value)
                        }
                      >
                        {value.UserId === editing?.UserId ? (
                          <ClearIcon />
                        ) : (
                          <ModeEditOutlinedIcon />
                        )}
                      </Button>
                      <Button
                        sx={{ pointerEvents: "auto" }}
                        size="small"
                        color="secondary"
                        onClick={(e) =>
                          handleDelete(value, propsComponent.listType)
                        }
                      >
                        <Icon icon="ph:trash-light" fontSize={19} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
          if (isDragged) {
            return (
              <Box {...props} sx={{ cursor: "grabbing" }}>
                {rowcontent}
              </Box>
            );
          }
          return (
            <Box {...props} sx={{ cursor: "grabbing" }}>
              {rowcontent}
            </Box>
          );
        }}
      />
    </Box>
  );
};
