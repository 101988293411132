import { documentConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const RegDocFieldData = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.REGISTER_DOCUMENT_FIELDATA_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case documentConstants.REGISTER_DOCUMENT_FIELDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case documentConstants.REGISTER_DOCUMENT_FIELDATA_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case documentConstants.REGISTER_DOCUMENT_FIELDATA_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const UploadTemplateManagerData = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.UPLOAD_TEMPLATE_MANAGER_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case documentConstants.UPLOAD_TEMPLATE_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case documentConstants.UPLOAD_TEMPLATE_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case documentConstants.UPLOAD_TEMPLATE_MANAGER_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};
