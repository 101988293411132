import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import VisorPDF from "../../../components/Viewer/VisorPDF";
import Comentarios from "../ComponentsDocument/Coments";
import Metadatos from "../ComponentsDocument/Metadatos";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentVersions,
  setDocument,
} from "../../../store/actions";
import samplePDF from "../../../assets/dummy.pdf";
import { FileService } from "../../../services/FileService";
import { LoadingButton } from "@mui/lab";
import Spinner from "../../../components/Spinner";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  var atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default function DocumentVersions({ setValue }) {
  const document = useSelector((state) => state.File);
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const [locked, setLocked] = useState(true)
  const [comentarios, setComentarios] = useState([]);
  const [documentoElegido, setDocumentoElegido] = useState([]);
  const [docOne, setDocOne] = useState("");
  // Version selecionada
  const [documentVersion, setDocumentVersion] = useState([]);
  // URL de la version que se tomo
  const [urlVersion, setUrlVersion] = useState([]);


  const getVersionsDocuments = (item) => {
    // El item es el id del expediente
    // item?.DocumentData.Id
    setLoadingButton(false);
    setLocked(true);
    dispatch(getDocumentVersions({ Body: item?.DocumentData.Id }));
    dispatch(setDocument(item));
  };

  const getDocVersion = async (item) => {
    setLoadingButton(true);
    setDocumentoElegido(item);
    const documentVersions = FileService.getInstance();
    const response = await documentVersions.getVersionDoc({
      Body: {
        Document_Id: item?.Document_Id,
        Version: item?.Version,
      },
    });
    if (response.Body) {
      setLoadingButton(false);
      setLocked(false);
      setDocumentVersion(response.Body);
    }
  };

  // console.log(documentVersion)

  const buscarDocumento = () => {
    setComentarios(JSON.parse(documentoElegido.Comentario));

    if (documentVersion) {
      const blob = B64toBlob(documentVersion?.B64Content, "application/pdf");
      setUrlVersion(URL.createObjectURL(blob));
    };
  };

  useEffect(() => {
    setDocOne(document?.enlace);
  }, []);


  return (
    <Fragment>
      <Grid item lg={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography variant="subtitle1">
              Historial del Documento | Paso 1/4
              <Tip title={<Box>Hola mundo</Box>} placement="left" arrow enterTouchDelay={0}>
                <InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
              </Tip>
            </Typography>
          </Box>
          <Box
            sx={{
              p: 1,
              ml: 2,
              display: "flex",
              justifyItems: "center",
              minWidth: 254,
              justifyContent: "center",
            }}
            onClick={() => setValue(0)}
          >
            <Button fullWidth size="medium" variant="contained" color="primary">
              Regresar al flujo
            </Button>
          </Box>
        </Box>
      </Grid>
      <Box sx={{ p: 2, border: 1, borderColor: "secondary.main20" }}>
        <Grid container>
          <Grid item lg={12}>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              <Box>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ m: 1, minWidth: 270 }}
                >
                  <Box>
                    <Typography variant="body2">
                      Selecciona un documento
                    </Typography>
                  </Box>
                  <Select sx={{ height: 36 }} IconComponent={KeyboardArrowRight}>
                    <MenuItem value="">
                      <em>Selecciona un documento</em>
                    </MenuItem>
                    {document?.item?.Documents.map((item) => (
                      <MenuItem
                        key={item.DocumentData.Id}
                        value={item.DocumentData.Id}
                        onClick={() =>
                          getVersionsDocuments(item)
                        }
                      >
                        {item?.FileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ m: 1, minWidth: 270 }}
                >
                  <Box>
                    <Typography variant="body2">
                      Selecciona una versión
                    </Typography>
                  </Box>
                  <Select sx={{ height: 36 }} IconComponent={KeyboardArrowRight}>
                    <MenuItem >
                      <em>Selecciona una versión</em>
                    </MenuItem>
                    {document?.doc_versions?.map((item) => (
                      <MenuItem
                        key={item.Id}
                        value={item.Version}
                        onClick={() => getDocVersion(item)}
                      >
                        {item?.VersionDate}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <LoadingButton
                  sx={{ minWidth: 270, height: 36 }}
                  variant="contained"
                  disabled={locked}
                  size="medium"
                  onClick={() => buscarDocumento()}
                  loading={loadingButton}
                >
                  Buscar
                </LoadingButton>
                {/* <Button
                  size="medium"
                  sx={{ minWidth: 270, height: 36 }}
                  variant="outlined"
                  onClick={() => buscarDocumento()}
                >
                  Buscar
                </Button> */}
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box sx={{ mt: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: 500 }} >
                {document?.document?.FileName}
              </Typography>
            </Box>
            <Grid>
              <Metadatos fileState={document} />
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ pr: 1 }}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="caption" color="primary.main80">
                  <b>Ultima versión</b> {""}
                </Typography>
              </Box>
              {docOne ?
                <VisorPDF
                  pdfUrl={docOne}
                  pdfName={document?.document_64?.FileName}
                />
                : <Spinner />
              }
              <Box sx={{ mt: 1 }}>
                <Comentarios comment={true} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ pl: 1 }}>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="caption">
                    <b>Version del</b> {documentoElegido.VersionDate}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    Expandir ambos documentos
                  </Typography>
                </Box>
              </Box>
              {urlVersion || samplePDF ?
                <VisorPDF
                  pdfUrl={urlVersion.length !== 0 ? urlVersion : samplePDF}
                  pdfName={documentVersion ? documentVersion?.FileName : ''}
                />
                : <Spinner />
              }
              <Box sx={{ mt: 1 }}>
                <Comentarios comment={false} data={comentarios} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
