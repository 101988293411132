import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Draggable from "react-draggable";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import SimpleWhite from "../../assets/SimpleWhite.png";
import AdvanceWhite from "../../assets/AdvanceWhite.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ContinueWorkFlow, getFile } from "../../store/actions";
import { LoadingButton } from "@mui/lab";
import { FileFlowService } from "../../services/FileFlowService";
import { FileService } from "../../services/FileService";
import { Alert } from "../../components/Alert";
import BackdropComponent from "../../components/BackDrop";
import { UseImage } from "../../components/UseImage";
import { DocumentService } from "../../services/DocumentService";
import HelpVideoEdicion from "../../assets/FirmadoZonas.mp4";
import HelpPages from "../../components/HelpPages.jsx";
import { CustomModalAlert } from "../../components/CustomModalAlert";
import { useNavigate } from "react-router-dom";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { GenerateRandom } from "../../utils";

const ButtonDocLIst = styled(Button)(({ Button }) => ({
  width: "100%",
  color: " black",
  fontSize: "15px",
  fontWeight: 500,
  minHeight: "44px",
  maxHeight: 60,
  borderRadius: "5px",
  display: "flex",
  textAlign: "left",
  alignItems: "flex-start",
  padding: "0.7em",
  marginTop: "5px",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

export default function ConfigFirmDrag(props) {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listSignersCreation = useSelector(
    (state) => state.NewFile.participants.sign
  );
  const listDocumentsNew = useSelector(
    (state) => state.NewFile?.documentsStack
  );
  const listDocumentsEdit = useSelector((state) => state.File.item?.Documents);
  const [loadignDoc, setLoadignDoc] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [stateParticipants, setStateParticipants] = useState(false);
  const [blockButtonDocument, setBlockButtonDocument] = useState(false);
  const [mueve, setMueve] = useState(true);
  const [imagesDocument, setImagesDocument] = useState([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const [usersSigners, setUsersSigners] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  const [activeSendData, setActiveSendData] = useState(false);

  useEffect(() => {
    if (fileState?.item?.Participants.length === 0) {
      setUsersSigners(listSignersCreation);
      newListUsers(listSignersCreation);
    } else {
      let listSignersFilter = fileState?.item?.Participants.filter(
        (item) => item?.Sign === true
      );
      newListUsers(listSignersFilter);
      setUsersSigners(listSignersFilter);
    }
  }, [fileState?.item, listSignersCreation]);

  const newListUsers = async (users) => {
    const storageKey = "miArrayObjetos";
    const documentService = DocumentService.getInstance();
    const responseVerification =
      await documentService.VerifyTraceSignConfiguration(
        folioIdSolicitud ? folioIdSolicitud : fileState?.item?.FileData?.Id
      );
    if (responseVerification.data.Response === null) {
      const arrayObjetos = users?.map((objeto) => {
        return {
          id: objeto.UserId,
          contador: 0,
        };
      });
      sessionStorage.setItem(storageKey, JSON.stringify(arrayObjetos));
    } else {
      console.log("entro 2", responseVerification?.data.Response?.m_Item3);
      const arrayObjetos = responseVerification?.data.Response?.m_Item3?.map(
        (objeto) => {
          return {
            id: objeto.User_Id,
            contador: objeto.CountSign,
          };
        }
      );
      sessionStorage.setItem(storageKey, JSON.stringify(arrayObjetos));
    }
  };

  const nextStage = async (skip) => {
    try {
      if (props.redEdition === true) {
        let Body = {
          File_Id: fileState?.item?.FileData?.Id,
          Status: null,
          Result: null,
          Stage_Id: null,
          Comments: null,
        };
        dispatch(ContinueWorkFlow(Body));
        console.log("EDICION DE ARCHIVO");
        return navigate("/inbox/files");
      } else {
        setLoadingNext(true);
        const fileFlowService = FileFlowService.getInstance();
        await fileFlowService.registerStatusStage({
          File_id: folioIdSolicitud,
          Status: "Vista previa",
          Result: null,
        });
        if (newFileState.editStack) {
          dispatch(getFile({ FileId: folioIdSolicitud }));
          setLoadingNext(false);
          if (skip === true) {
            skipStep();
          }
          props.handleNextStep();
        } else {
          setLoadingNext(false);
          if (skip === true) {
            skipStep();
          }
          props.handleNextStep();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const skipStep = async () => {
    console.log("ENTRO-SKIP 1");
    try {
      const documentService = DocumentService.getInstance();
      await documentService.DeleteTraceSignDocument(folioIdSolicitud);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listDocumentsNew.length > 0) {
      setImagesDocument(null);
      traerDocumentoImages(
        listDocumentsNew[0]?.DocResponseId ||
          listDocumentsNew[0]?.DocumentData?.Id
      );
      setDocumentList(listDocumentsNew);
    } else {
      if (fileState.item) {
        setImagesDocument(null);
        traerDocumentoImages(listDocumentsEdit[0]?.DocumentData?.Id);
        setDocumentList(listDocumentsEdit);
      }
    }
  }, [fileState.item]);

  useEffect(() => {
    if (activeSendData) {
      setActiveSendData(false);
      enviarDatos();
    }
  }, [coordinates, activeSendData]);

  const traerDocumentoImages = async (idFile) => {
    setBlockButtonDocument(true);

    setImagesDocument(null);
    setDocumentoSeleccionado(idFile);

    try {
      setLoadignDoc(true);
      const fileService = FileService.getInstance();
      const responseDocsImages = await fileService.GetConvertDocumentsToImages({
        Document_Id: idFile,
        PageIndex: 1
      });

      console.log("responseGetConvertDocumentsToImages-----------211", responseDocsImages);
      if (
        responseDocsImages.Message !== null &&
        responseDocsImages.Message !== undefined &&
        responseDocsImages.Message !== ""
      ) {
        return Alert({
          icon: "error",
          okbtntext: "Aceptar",
          text: `${responseDocsImages.Message}`,
        });
      }
      if (responseDocsImages.Response?.documentConvertResultData?.TracesSign !== null) {
        console.log("responseConvertDoc1 2", responseDocsImages);
        console.log("ENTRO 1");
        setCoordinates(responseDocsImages?.Response?.documentConvertResultData?.TracesSign?.Traces);
        setImagesDocument(responseDocsImages?.Response?.documentConvertResultData);
        setMueve(false);
        setBlockButtonDocument(false);
        console.log("dataDatos 0", responseDocsImages);
      } else {
        console.log("responseConvertDoc1 3", responseDocsImages);
        console.log("ENTRO 2");
        let newData = [];
        let numHojas = responseDocsImages?.Response?.documentConvertResultData?.Properties.length;
        for (var i = 0; i < numHojas; i++) {
          newData.push({
            NumberPage: i + 1,
            Points: [],
          });
        }
        console.log("dataDatos 1", responseDocsImages?.Response);
        setImagesDocument(responseDocsImages?.Response?.documentConvertResultData);
        setCoordinates(newData);
        console.log("numHojas", numHojas, newData);
        setMueve(true);
        setBlockButtonDocument(false);
      }
      // }
      console.log("ENTRO 3");
    } catch (error) {
      setBlockButtonDocument(false);
      setLoadignDoc(false);
      console.log(error);
      if (error?.Message !== undefined && error?.Message !== null) {
        Alert({
          icon: "error",
          okbtntext: "Aceptar",
          text: `${error.Message}`,
        });
      }
    } finally {
      setBlockButtonDocument(false);
      setLoadignDoc(false);
    }
  };

  const enviarDatos = async () => {
    setLoadignDoc(true);
    console.log("bodyRequestCoordinates 1", coordinates);
    try {
      coordinates.forEach((element, index) => {
        if (element.NumberPage === null || element.NumberPage === 0) {
          coordinates.splice(index, 1);
        }
      });
      const Body = {
        Document_Id: documentoSeleccionado,
        TotalPage: imagesDocument?.Properties.length,
        Traces: coordinates,
      };
      const fileService = FileService.getInstance();
      await fileService.AddTraceSignDocument({ Body });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadignDoc(false);
    }
  };

  const sendDataCoordinates = async () => {
    try {
      setLoadignDoc(true);
      console.log("bodyRequestCoordinates 2", coordinates);
      coordinates.forEach((element, index) => {
        if (element.NumberPage === null || element.NumberPage === 0) {
          coordinates.splice(index, 1);
        }
      });
      const Body = {
        Document_Id: documentoSeleccionado,
        TotalPage: imagesDocument?.Properties.length,
        Traces: coordinates,
      };
      console.log("bodyRequestCoordinates 3", Body);
      const fileService = FileService.getInstance();
      const response = await fileService.AddTraceSignDocument({ Body });
      console.log("responseEnvio", response);
      const documentService = DocumentService.getInstance();
      const responseVerification =
        await documentService.VerifyTraceSignConfiguration(
          folioIdSolicitud ? folioIdSolicitud : fileState?.item?.FileData?.Id
        );
      console.log("RESPONSEENVIO", responseVerification);
      if (responseVerification?.data?.Response?.m_Item2 === true) {
        nextStage();
      } else {
        Alert({
          icon: "error",
          okbtntext: "Aceptar",
          html: responseVerification?.data?.Response?.m_Item1,
        });
      }
    } catch (error) {
      console.log("errorerror", error);
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: `Ocurrió un error. Consulta soporte con tu proveedor.`,
      });
    } finally {
      setLoadignDoc(false);
    }
  };

  const activeUser = (item) => {
    return participantSelected?.UserId === item?.UserId;
  };

  const activeDocument = (item) => {
    return (
      documentoSeleccionado === (item?.DocumentData?.Id || item?.DocResponseId)
    );
  };

  return (
    <>
      <Grid container>
        <Grid container lg={3} sm={12} md={6} xs={12}>
          <Box>
            <Grid lg={12} md={8} dm={12} xs={12} sx={{ mt: 1 }}>
              <ButtonDocuments
                blockButtonDocument={blockButtonDocument}
                activeDocument={activeDocument}
                enviarDatos={enviarDatos}
                setCoordinates={setCoordinates}
                DocumentList={documentList}
                traerDocumentoImages={traerDocumentoImages}
                setLoadignDoc={setLoadignDoc}
              />
            </Grid>
            <Grid lg={12} md={8} xs={12}>
              <ButtonParticipant
                Title={"Firmantes"}
                activeUser={activeUser}
                ParticipantList={usersSigners}
                setParticipant={setParticipantSelected}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={8} sm={12} md={12} xs={12} sx={{ ml: 2 }}>
          <Box textAlign={"justify"} sx={{ p: 1 }}>
            <Typography>
              Para plasmar una firma, selecciona un documento, una vez que estés
              en él, selecciona el firmante que deseas plasmar, da clic sobre
              cualquier zona del documento para agregar su firma y aparecerá un
              recuadro de color con el nombre del firmante, arrástralo en la
              zona del documento donde desees visualizar su firma.
            </Typography>
          </Box>
          {imagesDocument?.Properties ? (
            <Arrastrable
              setStateParticipants={setStateParticipants}
              enviarDatos={enviarDatos}
              setCoordinates={setCoordinates}
              coordinates={coordinates}
              documentoSeleccionado={documentoSeleccionado}
              usersSigners={usersSigners}
              mueve={mueve}
              setMueve={setMueve}
              documentList={documentList}
              imagesDocument={imagesDocument}
              loadignDoc={loadignDoc}
              ParticipantSelected={participantSelected}
              setParticipantSelected={setParticipantSelected}
              setActiveSendData={setActiveSendData}
            />
          ) : (
            <Spinner />
          )}
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
          {props.redEdition === true ? null : (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                sx={{ height: 45, px: 10 }}
                onClick={() => {
                  dispatch(getFile({ FileId: folioIdSolicitud }));
                  props.handleBackStep();
                }}
              >
                Anterior
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <LoadingButton
              loading={loadingNext}
              fullWidth
              variant="contained"
              sx={{ height: 45, px: 0 }}
              onClick={() => {
                // nextStage(true);
                setModalAlert(true);
              }}
            >
              Omitir
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LoadingButton
              fullWidth
              loading={loadingNext}
              // disabled={props.redEdition === true ? null : !stateParticipants}
              variant="contained"
              sx={{ height: 45, px: 0 }}
              onClick={() => {
                sendDataCoordinates();
              }}
            >
              Continuar
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <HelpPages
        Body={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <video
              style={{ width: "75%" }}
              className="HelpVideo"
              src={HelpVideoEdicion}
              autoPlay
              loop
              muted
            />
          </Box>
        }
      />

      <CustomModalAlert
        OnClose={() => {
          setModalAlert(false);
        }}
        Open={modalAlert}
        Title="¿Deseas omitir este paso?"
        SubTitle='Al omitir este paso, las firmas no podrán incluirse en el cuerpo de los documentos en las secciones específicas, ya que no fue configurada una zona dentro del documento, únicamente se visualizarán en la hoja adicional "Firma Electrónica de documentos"'
        LeftButtonText="Omitir paso"
        LeftButtonAction={() => {
          nextStage(true);
        }}
        RightButtonText="Configurar zona"
        RightButtonAction={() => setModalAlert(false)}
      />
    </>
  );
}

const Arrastrable = (props) => {
  const [imageSeleted, setImageSeleted] = useState([]);
  const [coordenadas, setCoordenadas] = useState(null);
  const [coordenadasUser, setCoordenadasUser] = useState(null);
  const [participants, setParticipants] = useState([]);
  const valRandom = GenerateRandom();

  function validacionParticipantes(id, valor) {
    const storageKey = "miArrayObjetos";
    let arrayObjetos = JSON.parse(sessionStorage.getItem(storageKey)) || [];
    const objetoExistente = arrayObjetos.find((objeto) => objeto.id === id);
    if (objetoExistente) {
      objetoExistente.contador += valor;
    } else {
      const nuevoObjeto = { id: id, contador: valor };
      arrayObjetos.push(nuevoObjeto);
    }
    sessionStorage.setItem(storageKey, JSON.stringify(arrayObjetos));
    const estado = arrayObjetos.some((objeto) => objeto.contador >= 1);
    props.setStateParticipants(estado);
  }

  const signatureTypeFile = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  const generateUniqueId = () => {
    const newUniqueId = Math.floor(valRandom * 1000000);
    return newUniqueId.toString();
  };

  const newParticipant = () => {
    console.log(
      ".:newParticipant:. ----- props.ParticipantSelected",
      props.ParticipantSelected
    );
    if (props.ParticipantSelected) {
      props.setMueve(false);
      console.log(
        ".:newParticipant:. ----- newArrayParticipant",
        props.ParticipantSelected
      );
      let newArrayParticipant = {
        User_Id: props.ParticipantSelected.UserId,
        TempId:
          props.ParticipantSelected.UserId.toString() + generateUniqueId(),
        NameUser: props.ParticipantSelected.FullName,
        PointX: 0,
        PointY: 0,
        Color: props.ParticipantSelected.Color
          ? props.ParticipantSelected.Color
          : Colors[0].color,
        TipoFirma: signatureTypeFile?.SignatureType
          ? signatureTypeFile?.SignatureType
          : props.ParticipantSelected.SignatureOptions.SignatureType,
        Width: 390,
        Height: signatureTypeFile?.SignatureType
          ? signatureTypeFile?.SignatureType === "Avanzada"
            ? 245
            : 190
          : props.ParticipantSelected.SignatureOptions.SignatureType ===
            "Avanzada"
          ? 245
          : 190,
      };
      validacionParticipantes(newArrayParticipant.User_Id, 1);
      const newParticipants = [...participants, newArrayParticipant];
      console.log(".:newParticipant:. ----- newParticipants", newParticipants);
      setParticipants(newParticipants);
      props.setParticipantSelected(null);
      setTimeout(() => {
        props.setMueve(true);
      }, 80);
    }
  };

  useEffect(() => {
    console.log(
      "++++++++++++++CoordenadasPrevias 0++++++++++++++++++",
      props.coordinates,
      imageSeleted?.NumberPage
    );
    props.coordinates.forEach((element) => {
      if (element?.NumberPage === imageSeleted?.NumberPage) {
        setParticipants(element.Points);
      }
    });
  }, [imageSeleted]);

  function nextImage(final) {
    let pagina = imageSeleted.NumberPage;
    props.setMueve(false);
    if (
      props?.imagesDocument?.Properties[
        props?.imagesDocument?.Properties.length - 1
      ].NumberPage === pagina
    ) {
    } else if (final === true) {
      setImageSeleted(
        props?.imagesDocument?.Properties[
          props?.imagesDocument?.Properties.length - 1
        ]
      );
      props.setParticipantSelected(null);
      setParticipants([]);
    } else {
      setImageSeleted(props?.imagesDocument?.Properties[pagina]);
      props.setParticipantSelected(null);
      setParticipants([]);
    }
    setCoordenadas(null);
  }

  function prevImage(inicio) {
    let pagina = imageSeleted.NumberPage;
    props.setMueve(false);
    if (pagina === 1) {
    } else if (inicio === true) {
      setImageSeleted(props?.imagesDocument?.Properties[0]);
      props.setParticipantSelected(null);
      setParticipants([]);
    } else {
      setImageSeleted(props?.imagesDocument?.Properties[pagina - 2]);
      props.setParticipantSelected(null);
      setParticipants([]);
    }
    setCoordenadas(null);
  }

  useEffect(() => {
    if (props.imagesDocument) {
      setImageSeleted(props.imagesDocument.Properties[0]);
    }
  }, []);

  useEffect(() => {
    if (
      coordenadasUser !== undefined &&
      coordenadasUser !== null &&
      coordenadasUser !== ""
    ) {
      let replaceId = coordenadasUser.TempId;
      let indexPoints = props.coordinates.find(
        (element) => element?.NumberPage === imageSeleted?.NumberPage
      );
      let newData = [];

      if (indexPoints && indexPoints.Points && indexPoints.Points.length > 0) {
        newData = [...indexPoints.Points]; // Copiamos las coordenadas existentes en newData
        setCoordenadas(indexPoints?.Points);
      }

      let index = newData?.findIndex((element) => element.TempId === replaceId);

      if (index > -1) {
        // Reemplazar coordenadas existentes
        let newCoordenadas = newData.map((element) => {
          if (element.TempId === replaceId) {
            return { ...element, ...coordenadasUser };
          } else {
            return element;
          }
        });
        newData = newCoordenadas;
      } else {
        // Agregar nuevas coordenadas
        if (
          coordenadasUser !== undefined &&
          coordenadasUser !== null &&
          coordenadasUser !== ""
        ) {
          newData.push(coordenadasUser);
        }
      }
      setCoordenadas(newData);
    }
  }, [coordenadasUser]);

  useEffect(() => {
    console.log("hasNonEmptyObject", coordenadas?.length);
    if (coordenadas?.length > 0) {
      console.log("BORRANDOTODO 0");
      let replaceId = imageSeleted?.NumberPage;
      let arrayNew = {
        NumberPage: imageSeleted ? imageSeleted?.NumberPage : null,
        Points: coordenadas,
      };
      let index = props.coordinates?.findIndex(
        (element) => element?.NumberPage === replaceId
      );

      console.log("props.coordinates", props.coordinates, coordenadas);

      console.log("index 1", index);
      if (index > -1) {
        let newCoordenadasForPage = props.coordinates.map((element) => {
          if (element.NumberPage === replaceId) {
            return { ...element, ...arrayNew };
          } else {
            return element;
          }
        });
        console.log("BORRANDOTODO 0.0", newCoordenadasForPage);
        props.setCoordinates(newCoordenadasForPage);
      } else {
        if (
          arrayNew.NumberPage !== null &&
          arrayNew.NumberPage !== undefined &&
          arrayNew.NumberPage !== ""
        ) {
          props.coordinates.push(arrayNew);
        }
      }
    }
  }, [coordenadas]);

  const eliminarDato = (item) => {
    props.setMueve(false);
    console.log("filterEliminatePaticipants 0", coordenadas);
    let idPage = imageSeleted?.NumberPage;
    let tempId = item.TempId;

    validacionParticipantes(item.User_Id, -1);

    var UpdateArrayCoords = props.coordinates?.map((itemCoord) =>
      itemCoord.NumberPage === idPage
        ? {
            ...itemCoord,
            Points: itemCoord?.Points?.filter(
              (itemCoord) => itemCoord.TempId !== tempId
            ),
          }
        : itemCoord
    );

    const objetoEncontrado = UpdateArrayCoords.find(
      (objeto) => objeto.NumberPage === idPage
    );

    var filterEliminateCoordenadas = coordenadas?.filter(
      (itemFilter) => itemFilter.TempId !== item.TempId
    );

    setCoordenadas(filterEliminateCoordenadas);
    props.setCoordinates(UpdateArrayCoords);
    setParticipants(objetoEncontrado.Points);
    props.setActiveSendData(true);
    setTimeout(() => {
      props.setMueve(true);
    }, 80);
  };

  const documentNameSelected = props?.documentList.filter(
    (item) =>
      (item.DocResponseId || item?.DocumentData?.Id) ===
      props.documentoSeleccionado
  );

  console.log("participantsQuedantes", participants);

  return (
    <Paper elevation={2}>
      <BackdropComponent loading={props.loadignDoc} />
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          zIndex: 1,
          width: imageSeleted?.PointWidth,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 500, ml: 2 }}>
            {documentNameSelected[0]?.file?.name ||
              documentNameSelected[0]?.FileName}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            <IconButton
              disabled={imageSeleted?.NumberPage === 1}
              sx={{ p: 0 }}
              onClick={() => prevImage(true)}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              disabled={imageSeleted?.NumberPage === 1}
              sx={{ p: 0 }}
              onClick={() => prevImage()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Box>
          <Box sx={{ fontWeight: 550 }}>{imageSeleted?.NumberPage}</Box>
          <Box>
            <IconButton
              disabled={
                imageSeleted?.NumberPage ===
                props?.imagesDocument?.Properties.length
              }
              sx={{ p: 0 }}
              onClick={() => nextImage()}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              disabled={
                imageSeleted?.NumberPage ===
                props?.imagesDocument?.Properties.length
              }
              sx={{ p: 0 }}
              onClick={() => nextImage(true)}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex" }}>
            de{" "}
            <Typography sx={{ fontWeight: 550, ml: 0.5 }}>
              {props?.imagesDocument?.Properties?.length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <div
          style={{
            height: "800px",
            width: "auto",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
          onClick={() => newParticipant()}
        >
          <div style={{ height: "auto", width: "auto", padding: "10px" }}>
            {participants?.map((use, index) => {
              console.log("useuseuse", index, use);
              return (
                <Participantes
                  eliminarDato={eliminarDato}
                  item={use}
                  NameUser={use.NameUser}
                  index={index}
                  setMueve={props.setMueve}
                  mueve={props.mueve}
                  coordenada={use}
                  setCoordenadasUser={setCoordenadasUser}
                />
              );
            })}
            <Box>
              <Box sx={{ mt: 1 }}>
                {imageSeleted ? (
                  <Paper elevation={4} sx={{ width: imageSeleted?.PointWidth }}>
                    <img
                      src={`data:image/jpeg;base64,${imageSeleted.Content}`}
                      alt="imagen de contrato"
                      style={{
                        width: imageSeleted?.PointWidth,
                        height: imageSeleted?.PointHeight,
                        marginTop: "60px",
                        border: `1.5px solid #ECECEC`,
                      }}
                    />
                  </Paper>
                ) : (
                  <Spinner />
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

const Participantes = ({
  item,
  NameUser,
  setCoordenadasUser,
  setMueve,
  mueve,
  coordenada,
  index,
  eliminarDato,
}) => {
  const [newCoordenada, setNewCoordenada] = useState({});

  const onDrag = (e, data) => {
    if (data.lastY - 72 <= 0) {
      return;
    }
    setMueve(true);
    setCoordenadasUser({
      User_Id: item?.User_Id,
      TempId: item?.TempId,
      NameUser: item?.NameUser,
      PointX: data.lastX,
      PointY: data.lastY - 72,
      Color: item.Color,
      TipoFirma: item.TipoFirma,
      Width: item.Width,
      Height: item.Height,
    });
  };

  useEffect(() => {
    setNewCoordenada({
      PointX: coordenada.PointX,
      PointY: coordenada.PointY,
    });
  }, [coordenada, index]);

  return (
    <Draggable
      onDrag={onDrag}
      bounds="parent"
      zIndex={index + 1}
      position={
        mueve === true
          ? false
          : {
              x: newCoordenada.PointX === 0 ? 0 : newCoordenada.PointX,
              y: newCoordenada.PointY === 0 ? 0 : newCoordenada.PointY + 72,
            }
      }
    >
      <div
        style={{
          cursor: "move",
          float: "left",
          padding: 2,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: index + 1,
        }}
      >
        <div
          style={{
            width: 120,
            display: "flex",
            justifyContent: "center",
          }}
          className={"draggable"}
        >
          <div
            style={{
              height: 55,
              width: 120,
              background: `${item.Color}`,
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 3,
                  marginTop: ".2em",
                }}
              >
                {item.TipoFirma === "Simple" ? (
                  <UseImage
                    type="Image"
                    width={22}
                    height={22}
                    src={"SignSimple.png"}
                  />
                ) : item.TipoFirma === "Avanzada" ? (
                  <UseImage
                    type="Image"
                    width={22}
                    height={22}
                    src={"SignAvanzada.png"}
                  />
                ) : null}
              </Box>
              <Box>
                <IconButton size="small" onClick={() => eliminarDato(item)}>
                  <spam style={{ fontSize: 14, fontWeight: 600 }}>x</spam>
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: 118, whiteSpace: "nowrap" }}>
                <Box
                  component="div"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "8px!important", fontWeight: 500 }}
                  >
                    {NameUser}
                  </Typography>
                </Box>
              </div>
            </Box>
            <Box
              component="div"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "8px!important", fontWeight: 450 }}>
                Fecha y hora
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
const ButtonDocuments = ({
  DocumentList,
  traerDocumentoImages,
  enviarDatos,
  activeDocument,
  blockButtonDocument,
}) => {
  return (
    <Paper elevation={2} style={{ width: "83%" }}>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Documentos
        </Typography>
      </Box>
      <Box sx={{ width: "90%", p: 1, maxHeight: "90%", overflow: "auto" }}>
        {DocumentList?.map((item, index) => (
          <Box>
            <WhiteTooltip
              placement="top"
              title={item.FileName || item?.DocumentConfiguration?.FileName}
            >
              <ButtonDocLIst
                disabled={blockButtonDocument}
                sx={{
                  color: activeDocument(item) ? "#C20E30" : "black",
                  fontWeight: activeDocument(item) ? 600 : 500,
                  borderColor: activeDocument(item) ? "#C20E30" : "black",
                  background: activeDocument(item) ? "#C20E3026" : "",
                }}
                onClick={() => {
                  enviarDatos();
                  traerDocumentoImages(
                    item.DocumentData?.Id || item?.DocResponseId
                  );
                }}
                key={index}
                variant="outlined"
                id={item.DocumentData?.Id || item?.DocResponseId}
              >
                <Typography sx={{ mb: 0.5 }}>
                  {item.FileName || item?.DocumentConfiguration?.FileName}
                </Typography>
              </ButtonDocLIst>
            </WhiteTooltip>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const ButtonParticipant = ({
  ParticipantList,
  Title,
  setParticipant,
  activeUser,
}) => {
  const signatureTypeFile = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  return (
    <Paper elevation={2} sx={{ width: "83%", mt: 2 }}>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {Title}
        </Typography>
      </Box>
      <Box sx={{ width: "93%", p: 1, maxHeight: "290px", overflow: "auto" }}>
        {ParticipantList?.map((item, index) => (
          <Box
            border={activeUser(item) === true ? 1.9 : null}
            borderColor={activeUser(item) === true ? "#494949" : null}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: 0.8,
              mt: 1,
              borderRadius: "13px",
            }}
            onClick={() => {
              setParticipant(item);
            }}
          >
            <Box
              sx={{
                backgroundColor: item.Color,
                p: 1,
                mr: 1,
                borderRadius: "450%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                width: 45,
                height: 40,
              }}
            >
              <img
                src={
                  signatureTypeFile?.SignatureType
                    ? signatureTypeFile?.SignatureType === "Avanzada"
                      ? AdvanceWhite
                      : SimpleWhite
                    : item?.SignatureOptions?.SignatureType === "Simple"
                    ? SimpleWhite
                    : item?.SignatureOptions?.SignatureType === "Avanzada"
                    ? AdvanceWhite
                    : null
                }
                style={{ width: 25 }}
                alt="imageSing"
              />
            </Box>
            <Box sx={{ ml: 1, width: 200 }}>
              <Typography>{item.FullName}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};

let Colors = [
  {
    color: "#9900FF",
  },
  {
    color: "#009DFF",
  },
  {
    color: "#00CC3A",
  },
  {
    color: "#EEBA00",
  },
  {
    color: "#DD3500",
  },
  {
    color: "#B587FF",
  },
  {
    color: "#0066FF",
  },
  {
    color: "#097D53",
  },
  {
    color: "#EE8F00",
  },
  {
    color: "#AE0907",
  },
];
