// true: utiliza la ruta de publiucacion, false: utiliza localhost
export const constPathRoot = {
  pathRoot: true ? `${process.env.REACT_APP_BASENAME}` : ''
};

//Variable que indica la activacion de encriptacion a todas las peticiones
export const constEncripted = {
  encriptedBody: false
};

export const constUrlVisor = {
  ambient: `${process.env.REACT_APP_VISOR}`
};

export const constUrlGraficas = {
  ambient: `${process.env.REACT_APP_GRAFICAS}`
};

export const constUrlGraficasMovil = {
  ambient: `${process.env.REACT_APP_GRAFICAS_MOVIL}`
};