import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { EmptyTable } from "./EmptyTable";
import {
  getFile,
  editStack,
  GetFilesCustomMasive,
  RegisterBatchUploadSignedProcess,
} from "../../store/actions";
import { UTCtoLocal } from "../../utils";
import { GenericTableSelection } from "../../components/GenericTableSelection";
import BackdropComponent from "../../components/BackDrop";
import InboxSignatureResponsive from "./InboxSignatureResponsive";

moment.locale("es");

const columns = [
  {
    Header: "Folio",
    accessor: "invoice",
  },
  {
    Header: "Nombre documental",
    accessor: "product",
  },
  {
    Header: "Nombre del solicitante",
    accessor: "applicant",
  },
  {
    Header: "Fecha de creación",
    accessor: "creationDate",
  },
  {
    Header: "Fecha límite",
    accessor: "validity",
  },
  {
    Header: "Estatus",
    accessor: "status",
  },
  {
    Header: "Estado",
    accessor: "stage",
  },
];

export const InboxSignature = (props) => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [folioSelectedSignature, setFolioSelectedSignature] = useState([]);
  const [loading, setLoading] = useState(false);

  const folioIdSolicitud = useSelector(
    (state) => state.File?.item?.FileData?.Id
  );
  const getFilesCustomMasiveState = useSelector(
    (state) => state.GetFilesCustomMasiveData
  );
  const RegisterBatchUploadSignedProcessState = useSelector(
    (state) => state.RegisterBatchUploadSignedProcessData
  );

  const dispatch = useDispatch();
  const formMethods = useForm();

  useEffect(() => {
    if (folioIdSolicitud > 0) {
      dispatch(GetFilesCustomMasive({ File_Id: folioIdSolicitud }));
    }
  }, [folioIdSolicitud]);

  //Efecto para mapear la data de la api guardada en redux para la tabla
  useEffect(() => {
    console.log("getFilesCustomMasiveState: ", getFilesCustomMasiveState);
    if (getFilesCustomMasiveState?.items?.fileCustomDataResponse) {
      const filesTable =
        getFilesCustomMasiveState.items.fileCustomDataResponse.map((file) => {
          return {
            invoice: file.Id,
            product: file.Reference,
            applicant: file?.Applicant,
            creationDate: moment(file.CreationDate).format(
              "DD-MM-YYYY hh:mm:ss A"
            ),
            validity: UTCtoLocal(file.Vigencia, false),
            status: file.Status,
            stage: file.Tooltip,
            statusVigencia: file.StatusVigencia,
            productCode: file.ProductCode,
          };
        });
      setdata(filesTable);
    } else {
      setdata([]);
    }
  }, [getFilesCustomMasiveState]);

  useEffect(() => {
    console.log(
      "RegisterBatchUploadSignedProcessState: ",
      RegisterBatchUploadSignedProcessState
    );
    if (
      RegisterBatchUploadSignedProcessState?.items !== undefined &&
      RegisterBatchUploadSignedProcessState?.items !== null &&
      loading
    ) {
      if (RegisterBatchUploadSignedProcessState?.items.ResponseCode == 0) {
        sessionStorage.removeItem("ctrlExpediente");
        dispatch(editStack(false));
        console.log(
          ".............RegisterBatchUploadSignedProcessState............responde FileId.....................",
          RegisterBatchUploadSignedProcessState?.items?.Response?.File_Id
        );
        dispatch(
          getFile({
            FileId:
              RegisterBatchUploadSignedProcessState?.items?.Response?.File_Id,
          })
        );
        navigate("/inbox/file-information", {
          state: { ActiveMassiveSignature: true },
        });
      }
      dispatch(RegisterBatchUploadSignedProcess("", true));
      setLoading(false);
    } else if (
      RegisterBatchUploadSignedProcessState?.error !== undefined &&
      RegisterBatchUploadSignedProcessState?.error !== null &&
      loading
    ) {
      dispatch(RegisterBatchUploadSignedProcess("", true));
      setLoading(false);
    }
  }, [RegisterBatchUploadSignedProcessState]);

  useEffect(() => {
    formMethods.reset();
  }, [location.pathname, formMethods]);

  const handleSign = (row) => {
    // console.log("---------------folioIdSolicitud-------------: ", folioIdSolicitud)
    let request = {
      InnerFile_Ids: folioSelectedSignature,
    };
    console.log(
      "-----------RegisterBatchUploadSignedProcess ----request-------------: ",
      request
    );
    dispatch(RegisterBatchUploadSignedProcess(request));
    setLoading(true);
  };

  const renderTable = () => {
    return (
      <>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="subtitle1">
            Las solicitudes que se muestran a continuación pertenecen a una
            solicitud masiva, selecciona aquellas que quieras firmar en grupo
            (tu firma será replicada en las solicitudes seleccionadas)
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <InboxSignatureResponsive
            data={data}
            ClickSelectedRows={(data) => {
              console.log("ClickSelectedRows ---- --data------: ", data);
              var array = data.map((item) => {
                return item.invoice;
              }); // Se filtra solo el objeto original
              console.log("sleected: item: ", array);
              setFolioSelectedSignature(array);
            }}
          />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <GenericTableSelection
            Columns={columns}
            Data={data}
            onClickRow={() => {}}
            ShowPagination={false}
            ClickSelectedRows={(data) => {
              var array = data.map((item) => {
                return item.original.invoice;
              }); // Se filtra solo el objeto original
              console.log("sleected: item: ", array);
              setFolioSelectedSignature(array);
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <BackdropComponent loading={loading} />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Box sx={{ mt: 1 }}>
          {getFilesCustomMasiveState?.loading ? (
            <Box sx={{ my: 10, display: "grid", placeContent: "center" }}>
              <CircularProgress
                size={60}
                sx={{ margin: "auto", display: "flex" }}
              />
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1">Buscando</Typography>
              </Box>
            </Box>
          ) : (
            (function () {
              if (data.length === 0) {
                return <EmptyTable />;
              }
              return renderTable();
            })()
          )}
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ mt: 1, mb: 3 }}
        >
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: 45, px: 10 }}
              disabled={folioSelectedSignature?.length === 0}
              onClick={() => {
                handleSign();
              }}
            >
              Firmar
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </FormProvider>
  );
};
