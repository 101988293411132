import React, { useEffect, useState } from "react";
import {
  Accordion as Acc,
  AccordionSummary as AccSum,
  Alert as AlertMU,
  AccordionDetails,
  Button,
  Box,
  styled,
  Grid,
  Typography,
  Tooltip,
  tooltipClasses,
  IconButton,
  CircularProgress,
} from "@mui/material";
import iconoMenu from "../../assets/icons/Dragable.svg";
import plantillaRevisores from "../../assets/Plantilla_Revisores.xlsx";
import plantillaFirmantes from "../../assets/Plantilla_Firmantes.xlsx";
import LoadingButton from "@mui/lab/LoadingButton";
import iconExel from "../../assets/DescargaExcelIcon.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { ExpandMore } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { UploadGenericControl } from "../../components/UploadGenericControl";
import { GenericTable } from "../../components/GenericTable";
import { UploadDocumentParticipants } from "../../store/actions/MassiveActions";
import { UTCtoLocal } from "../../utils";
import {
  getFileCustomInfoMasive,
  setlistCheckers,
  setlistSigners,
} from "../../store/actions";
import IconToolTip from "../../components/IconToolTip";
import { Icon } from "@iconify/react";
import { UseImage } from "../../components/UseImage";
import { FileFlowService } from "../../services/FileFlowService";
import { Alert } from "../../components/Alert";
import { ParticipantDraggableMassiveTable } from "./ParticipantDraggableMassiveTable";
import moment from "moment";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
}));

const columns = [
  {
    Header: "Clave Solicitud Consecutivo",
    accessor: "invoice",
  },
  {
    Header: "Rol",
    accessor: "rol",
  },
  {
    Header: "Correo participante",
    accessor: "emailUser",
  },
  {
    Header: "Alias de Participante",
    accessor: "alias",
  },
  {
    Header: "Fecha límite",
    accessor: "validity",
  },
  {
    Header: "Estatus",
    accessor: "statusCheck",
  },
];

const columnsSigners = [
  {
    Header: "Clave Solicitud Consecutivo",
    accessor: "invoice",
  },
  {
    Header: "",
    accessor: "rol",
  },
  {
    Header: "Orden",
    accessor: "order",
  },
  {
    Header: "Correo participante",
    accessor: "emailUser",
  },
  {
    Header: "Alias de Participante",
    accessor: "alias",
  },
  {
    Header: "Fecha límite",
    accessor: "validity",
  },
  // {
  //   Header: "Tipo de firma",
  //   accessor: "typeSign",
  // },
  {
    Header: "Funciones",
    accessor: "funtions",
  },
  {
    Header: "Estatus",
    accessor: "statusSigner",
  },
];

export const ConfigFileMassiveTab = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataCheck, setDataCheck] = useState([]);
  const [dataSigners, setDataSigners] = useState([]);
  const [sendSignersActive, setSendSignersActive] = useState(false);
  const [sendCheckersActive, setSendCheckersActive] = useState(false);
  const [documentsCheck, setDocumentsCheck] = useState(true);
  const [expandedSigners, setExpandedSigners] = useState(false);
  const [expandedCheckers, setExpandedCheckers] = useState(true);
  const [b64DocumentChecks, setB64DocumentChecks] = useState(null);
  const [b64DocumentSigners, setB64DocumentSigners] = useState(null);
  const [loadingSigners, setLoadingSigners] = useState(false);
  const [loadingChecks, setLoadingChecks] = useState(false);
  const [validationUsersSigners, setValidationUsersSigners] = useState(true);
  const [validationUsersChecks, setValidationUsersChecks] = useState(true);

  const stateParticipants = useSelector((state) => state.ParticipantsMassive);
  const stateListDocs = useSelector((state) => state.ListDocStackMassiveData);
  const stockParticipants = useSelector((state) => state.NewFile);
  const stateFile = useSelector((state) => state.File);
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const environmentCtrlSigned = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.EnviromentVars
        ?.IsActiveSignatureZones
  );

  useEffect(() => {
    const status = stateListDocs?.ListDocStack.some(
      (objeto) => objeto.DocumentConfiguration.Check === true
    );
    const statusFile = stateFile?.item?.Documents.some(
      (objeto) => objeto.Check === true
    );
    setDocumentsCheck(status === false && statusFile === false ? false : true);
    if (status === false && statusFile === false) {
      setExpandedSigners(true);
    } else {
      const participantesAgrupados = stateFile?.item?.Grupos?.reduce(
        (resultado, elemento) => {
          const { Clave, Participants } = elemento;

          const participantesFiltrados = Participants.filter(
            (participante) =>
              participante.Check === true || participante.Reviewer === true
          );

          console.log("participantesFiltrados", participantesFiltrados);

          participantesFiltrados.forEach((participante) => {
            participante.DeadLineCheck = participante.CheckDeadline;
            participante.DeadLineReviewer = participante.ReviewerDeadLine;
            participante.State = true;
            participante.Role =
              participante.Check === true ? "Editor" : "Aprobador ";
            participante.Code = Clave;
          });

          return [...resultado, ...participantesFiltrados];
        },
        []
      );
      console.log("participantesAgrupados", participantesAgrupados);
      dispatch(setlistCheckers(participantesAgrupados));
    }
  }, [stateListDocs, stateFile?.item]);

  useEffect(() => {
    if (stateFile?.item?.Grupos) {
      const participantesAgrupados = stateFile?.item?.Grupos?.reduce(
        (resultado, elemento) => {
          const { Clave, Participants } = elemento;

          const participantesFiltrados = Participants.filter(
            (participante) => participante.Sign === true
          );

          participantesFiltrados.forEach((participante) => {
            participante.OrderSign = participante.SignOrder;
            participante.DeadLineSign = participante.SignDeadline;
            participante.State = true;
            participante.OTP =
              participante.SignatureOptions?.OTP === true ? "Sí" : "No";
            participante.Videograbacion =
              participante.SignatureOptions?.VideoRecording === true
                ? "Sí"
                : "No";
            participante.TipoFirma =
              participante.SignatureOptions?.SignatureType;
            participante.Code = Clave;
          });

          return [...resultado, ...participantesFiltrados];
        },
        []
      );
      dispatch(setlistSigners(participantesAgrupados));
      console.log("participantesAgrupados", participantesAgrupados);
    }
  }, [stateFile?.item]);

  useEffect(() => {
    console.log("stateParticipants.items", stateParticipants);
    if (stateParticipants.error !== null) {
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: `Ocurrió un error. ${stateParticipants?.error}.`,
      });
      setLoadingSigners(false);
      setLoadingChecks(false);
    } else {
      if (stateParticipants.items) {
        if (stateParticipants.items.MessageGlobal !== null) {
          Alert({
            icon: "error",
            okbtntext: "Aceptar",
            text: `${stateParticipants.items.MessageGlobal}.`,
          });
          setLoadingSigners(false);
          setLoadingChecks(false);
        }
        console.log("stateParticipants.items 1");
        if (stateParticipants?.items?.Participants) {
          if (b64DocumentChecks) {
            dispatch(setlistCheckers(stateParticipants?.items?.Participants));
            setB64DocumentChecks(null);
          } else if (b64DocumentSigners) {
            dispatch(setlistSigners(stateParticipants?.items?.Participants));
            setB64DocumentSigners(null);
          }
        }
      }
    }
  }, [stateParticipants.items, stateParticipants.error]);

  useEffect(() => {
    if (stockParticipants.participants?.check?.length > 0) {
      const filesTable = stockParticipants.participants?.check.map((file) => {
        return {
          invoice: file.Code,
          rol:
            file.Role === "Editor" ? (
              <Tip
                title={"Usuario Editor."}
                placement="top"
                enterTouchDelay={0}
              >
                <UseImage
                  type="Image"
                  width={23}
                  src={"Revisor.png"}
                  height={23}
                />
              </Tip>
            ) : (
              <Tip
                title={"Usuario Aprobador."}
                placement="top"
                enterTouchDelay={0}
              >
                <UseImage
                  type="Image"
                  width={23}
                  src={"AprobadorRevision.png"}
                  height={23}
                />
              </Tip>
            ),
          emailUser: file?.Email,
          alias: file.NameAlias,
          validity: moment(file.DeadLineCheck || file.DeadLineReviewer).format('DD-MM-YYYY'),
          // UTCtoLocal(
          //   file.DeadLineCheck || file.DeadLineReviewer,
          //   false
          // ),
          statusCheck:
            file.State === true ? (
              <Icon
                icon="el:ok-circle"
                fontSize={25}
                style={{
                  mb: "-1px",
                  mr: 1,
                  fontSize: 25,
                  color: "#0FA958",
                }}
              />
            ) : (
              <Tooltip title={file.ErrorToolTip} placement="top">
                <Icon
                  icon="zondicons:close-outline"
                  fontSize={25}
                  style={{
                    mb: "-1px",
                    mr: 1,
                    fontSize: 25,
                    color: "#D65E74",
                  }}
                />
              </Tooltip>
            ),
        };
      });
      const someUser = stockParticipants.participants?.sign.some(
        (objeto) => objeto.State === false
      );
      setValidationUsersChecks(someUser);
      setDataCheck(filesTable);
      setLoadingChecks(false);
    }
  }, [stockParticipants.participants.check]);

  useEffect(() => {
    if (stockParticipants.participants?.sign?.length > 0) {
      const filesTable = stockParticipants.participants?.sign.map((file) => {
        return {
          BatchUploadItem_Id: file.BatchUploadItem_Id,
          BatchUploadParticipant_Id: file.BatchUploadParticipant_Id,
          invoice: file.Code,
          order: file.OrderSign,
          rol:
            <Box
              sx={{
                width: 30,
                height: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={iconoMenu} alt="imagen" />
            </Box>,
          emailUser: file?.Email,
          alias: file.NameAlias,
          validity: moment(file.DeadLineSign).format('DD-MM-YYYY'),
          funtions: (
            <Box>
              <Box
                sx={{
                  width: 100,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconToolTip
                  icon={true}
                  iconColor={file.OTP == "Sí" ? "#D65E74" : "#979797"}
                  src={"teenyicons:otp-outline"}
                  title={"Contraseña de un solo uso."}
                  width={19}
                />
                {file.TipoFirma === "Firma Autógrafa en Mensaje de Datos" ? (
                  <IconToolTip
                    src={"SignSimple.png"}
                    title={"Firma Autógrafa en Mensaje de Datos."}
                    width={24}
                  />
                ) : file.TipoFirma === "Firma Electrónica Avanzada" ? (
                  <IconToolTip
                    src={"SignAvanzada.png"}
                    title={"Firma Electrónica Avanzada."}
                    width={22}
                  />
                ) : null}
                <IconToolTip
                  src={
                    file.Videograbacion == "Si"
                      ? "VideoIcon.svg"
                      : "VideoIconDisabled.svg"
                  }
                  title={"Videograbación de consentimiento."}
                  width={27}
                />
              </Box>
            </Box>
          ),
          statusSigner:
            file.State === true ? (
              <Icon
                icon="el:ok-circle"
                style={{
                  mb: "-1px",
                  mr: 1,
                  fontSize: 25,
                  color: "#0FA958",
                }}
              />
            ) : (
              <Tooltip title={file.ErrorToolTip} placement="top">
                <Icon
                  icon="zondicons:close-outline"
                  style={{
                    mb: "-1px",
                    mr: 1,
                    fontSize: 25,
                    color: "#D65E74",
                  }}
                />
              </Tooltip>
            ),
        };
      });
      const someUser = stockParticipants.participants?.sign.some(
        (objeto) => objeto.State === false
      );
      setValidationUsersSigners(someUser);
      setDataSigners(filesTable);
      setLoadingSigners(false);
    }
  }, [stockParticipants.participants.sign]);

  const uploadDataParticipants = (file, base64, extension, process) => {
    try {
      if (base64 !== null) {
        console.log("CAMBIO", {
          File_Id: folioIdSolicitud,
          B64Content: base64,
          Extension: extension,
          FileName: file?.name,
          Process: process,
        });
        if (process === "Firma") {
          setLoadingSigners(true);
        } else {
          setLoadingChecks(true);
        }
        dispatch(
          UploadDocumentParticipants({
            File_Id: folioIdSolicitud,
            B64Content: base64,
            Extension: extension,
            FileName: file?.name,
            Process: process,
          })
        );
      }
    } catch (error) {
      if (process === "Firma") {
        setLoadingSigners(false);
      } else {
        setLoadingChecks(false);
      }
      console.log("errorDeCarga", error);
    }
  };

  const sendDataChekcs = async () => {
    // dispatch(
    //   getFileCustomInfoMasive({
    //     Body: {
    //       FileId: folioIdSolicitud,
    //     },
    //   })
    // );
    dispatch(setlistSigners([]));
    setDataSigners([]);
    setB64DocumentSigners(null);
    setExpandedCheckers(false);
    setExpandedSigners(true);
    setSendCheckersActive(true);
  };

  const sendDataSigners = async () => {
    if (environmentCtrlSigned === true) {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Representación de firma",
        Result: null,
      });
      dispatch(
        getFileCustomInfoMasive({
          Body: {
            FileId: folioIdSolicitud,
          },
        })
      );
      props.handleNextStep();
    } else {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Vista previa",
        Result: null,
      });
      dispatch(
        getFileCustomInfoMasive({
          Body: {
            FileId: folioIdSolicitud,
          },
        })
      );
      props.handleNextStep();
    }
  };

  console.log("USUARIOSDATOS", dataSigners, dataCheck);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        {/* ACORDEON DE REVISORES */}
        {documentsCheck === true && (
          <Accordion
            disableGutters
            expanded={expandedCheckers}
            defaultExpanded={expandedCheckers}
          >
            <AccordionSummary
              onClick={() => setExpandedCheckers(!expandedCheckers)}
              expandIcon={<ExpandMore />}
              sx={{
                p: 0,
                "& .MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <Typography variant="titulo">
                {sendCheckersActive === true
                  ? "Proceso de Revisión"
                  : stockParticipants.participants?.check?.length > 0
                    ? "Verificación de información en Proceso de Revisión"
                    : "Configura las acciones que ejecutará cada Revisor"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
              {stockParticipants.participants?.check?.length > 0 ? (
                <>
                  <Box sx={{ flexGrow: 1, mb: 2 }}>
                    <Typography
                      variant="subtitle"
                      sx={{ fontWeight: 400, mt: 2 }}
                    >
                      {sendCheckersActive === true
                        ? "Si olvidaste agregar a un participante, puedes agregarlo editando tu plantilla de carga (Excel), y subirla nuevamente dando clic en el botón cargar archivo."
                        : "Verifica que la información sea correcta, si existe algún error deberás modificarlo y cargar nuevamente el archivo presionando el botón cargar archivo."}
                    </Typography>
                  </Box>
                  <GenericTable
                    Columns={columns}
                    Data={dataCheck}
                    TotalRows={stockParticipants?.participants?.check?.length}
                  />
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={3}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        sx={{ height: 45, px: 10 }}
                        onClick={() => {
                          dispatch(setlistCheckers([]));
                          setB64DocumentChecks(null);
                          setSendCheckersActive(false);
                        }}
                      >
                        Cargar archivo
                      </Button>
                    </Grid>
                    {sendCheckersActive === true ? null : (
                      <Grid item xs={12} sm={3}>
                        <Box>
                          <LoadingButton
                            fullWidth
                            loading={loading}
                            disabled={validationUsersChecks}
                            variant="contained"
                            sx={{ height: 45, px: 0 }}
                            onClick={() => sendDataChekcs()}
                          >
                            Siguiente
                          </LoadingButton>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="subtitle"
                        sx={{ fontWeight: 400, mt: 2, mb: 0.8 }}
                      >
                        Descarga la plantilla y captura los participantes que
                        serán responsables de la edición de los documentos. El
                        usuario administrador únicamente podrá llenar los
                        campos.
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title="Descargar el template" placement="top">
                        <a
                          href={plantillaRevisores}
                          target="_blank"
                          rel="noopener noreferrer"
                          download="Plantilla participantes revisores.xlsx"
                        >
                          <IconButton xs={{ width: 60 }}>
                            <img
                              alt="ImagenDescarga"
                              src={iconExel}
                              style={{ width: 45 }}
                            />
                          </IconButton>
                        </a>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1, mt: 1 }}>
                    <AlertMU
                      sx={{
                        backgroundColor: "#FFB80059",
                        color: "#000000",
                      }}
                      severity="info"
                    >
                      Clave solicitud consecutiva, es el separador lógico para
                      cada solicitud y este agrupa los participantes de una
                      solicitud, si la clave solicitud se repite 3 veces,
                      significa que esa solicitud tiene 3 participantes que
                      pueden asumir diferentes roles.
                    </AlertMU>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {loadingChecks === true ? (
                      <Spinner />
                    ) : (
                      <UploadGenericControl
                        Accept=".xlsx"
                        onChange={(file, base64, extension) => {
                          console.log("base64", base64);
                          if (
                            base64 === null &&
                            base64 === "" &&
                            base64 === undefined
                          ) {
                            return null;
                          } else {
                            console.log("ENTRO AQUI 2");
                            setB64DocumentChecks(base64);
                            uploadDataParticipants(
                              file,
                              base64,
                              extension,
                              "Revisión"
                            );
                          }
                        }}
                      />
                    )}
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {/* ACORDEON DE FIRMANTES */}
        <Accordion
          disableGutters
          expanded={expandedSigners}
          defaultExpanded={expandedSigners}
        >
          <AccordionSummary
            onClick={() => setExpandedSigners(!expandedSigners)}
            expandIcon={<ExpandMore />}
            sx={{
              p: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
            }}
          >
            <Typography variant="titulo">
              {sendSignersActive === true
                ? "Proceso de Firmado"
                : stockParticipants.participants?.check?.length > 0
                  ? "Verificación de información en Proceso de Firma"
                  : "Configura las acciones que ejecutará cada Firmante"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            {console.log(
              "DATASIGNERS",
              stockParticipants.participants.sign.length
            )}
            {stockParticipants.participants.sign.length > 0 ? (
              <>
                <Box sx={{ flexGrow: 1, mb: 2 }}>
                  <Typography
                    variant="subtitle"
                    sx={{ fontWeight: 400, mt: 2, mb: 2 }}
                  >
                    {sendSignersActive === true
                      ? "Si olvidaste agregar a un participante, puedes agregarlo editando tu plantilla de carga (Excel), y subirla nuevamente dando clic en el botón cargar archivo."
                      : "Verifica que la información sea correcta, si existe algún error deberás modificarlo y cargar nuevamente el archivo presionando el botón cargar archivo."}
                  </Typography>
                </Box>
                {/* <GenericTable
                  Columns={columnsSigners}
                  Data={dataSigners}
                  TotalRows={stockParticipants?.participants?.sign?.length}
                  TotalPages={1}
                  onClickRow={() => {}}
                  PageCurrent={1}
                  onClickChangePage={() => {}}
                  ShowPagination={false}
                /> */}

                <ParticipantDraggableMassiveTable
                  Columns={columnsSigners}
                  data={dataSigners}
                />

                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Grid item xs={12} sm={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      sx={{ height: 45, px: 10 }}
                      onClick={() => {
                        dispatch(setlistSigners([]));
                        setB64DocumentSigners(null);
                      }}
                    >
                      Cargar archivo
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Box>
                      <LoadingButton
                        fullWidth
                        loading={loading}
                        disabled={validationUsersSigners}
                        variant="contained"
                        sx={{ height: 45, px: 0 }}
                        onClick={() => sendDataSigners()}
                      >
                        Enviar
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="subtitle"
                      sx={{ fontWeight: 400, mt: 2, mb: 0.8 }}
                    >
                      Descarga la plantilla y captura los participantes que
                      serán responsables de la firma de los documentos. El
                      usuario administrador únicamente podrá llenar los campos.
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Descargar el template" placement="top">
                      <a
                        href={plantillaFirmantes}
                        target="_blank"
                        rel="noopener noreferrer"
                        download="Plantilla participantes firmantes.xlsx"
                      >
                        <IconButton xs={{ width: 60 }}>
                          <img
                            alt="ImagenDescarga"
                            src={iconExel}
                            style={{ width: 45 }}
                          />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </Box>
                </Box>
                <Box sx={{ mb: 1, mt: 1 }}>
                  <AlertMU
                    sx={{
                      backgroundColor: "#FFB80059",
                      color: "#000000",
                    }}
                    severity="info"
                  >
                    Clave solicitud consecutiva, es el separador lógico para
                    cada solicitud y este agrupa los participantes de una
                    solicitud, si la clave solicitud se repite 3 veces,
                    significa que esa solicitud tiene 3 participantes que pueden
                    asumir diferentes roles.
                  </AlertMU>
                </Box>
                <Box sx={{ mt: 3 }}>
                  {loadingSigners === true ? (
                    <Spinner />
                  ) : (
                    <UploadGenericControl
                      Accept=".xlsx"
                      onChange={(file, base64, extension) => {
                        console.log("base64 1", base64);
                        if (
                          base64 === null &&
                          base64 === "" &&
                          base64 === undefined
                        ) {
                          return null;
                        } else {
                          console.log("ENTRO AQUI 2");
                          setB64DocumentSigners(base64);
                          uploadDataParticipants(
                            file,
                            base64,
                            extension,
                            "Firma"
                          );
                        }
                      }}
                    />
                  )}
                </Box>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </RootStyle>
    </LocalizationProvider>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
