import { expedienteConstants } from "../constants";
import { FileService } from "../../services/FileService";

const request = () => {
  return {
    type: expedienteConstants.FILE_REQUEST,
  };
};

const success = (filesData) => {
  return {
    type: expedienteConstants.FILE_SUCCESS,
    payload: filesData,
  };
};

const failure = (error) => {
  return {
    type: expedienteConstants.FILE_FAILURE,
    payload: error,
  };
};

export const getFile = (filesData) => {
  console.log('filesData', filesData)
  return async (dispatch) => {
    dispatch(request());
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.getFile(filesData);
      dispatch(success(fileData.Body));
    } catch (error) {
      console.log('errorerror',error); 
      dispatch(failure(error.message));
    }
  };
};

export const getFileCustomInfoMasive = (filesData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.GetFileCustomInfoMasive(filesData);
      dispatch(success(fileData.Body));
    } catch (error) {
      console.log(error); 
      dispatch(failure(error.message));
    }
  };
};

const successDocument = (fileData) => {
  return {
    type: expedienteConstants.SET_DOCUMENT,
    payload: fileData,
  };
};

const requestDocument = () => {
  return {
    type: expedienteConstants.SET_DOCUMENT_REQUEST,
  };
};


export const setDocument = (document) => {
  console.log("Selected document consult: ", document)
  return async (dispatch) => {
    dispatch(requestDocument());
    try {
      dispatch(successDocument(document));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };
};

const requestComent = () => {
  return {
    type: expedienteConstants.COMENTS_REQUEST,
  };
};

const successComent = (filesData) => {
  return {
    type: expedienteConstants.COMENTS_SUCCESS,
    payload: filesData,
  };
};

const failureComent = (error) => {
  return {
    type: expedienteConstants.COMENTS_FAILURE,
    payload: error,
  };
};

const coments = (fileData) => {
  return {
    type: expedienteConstants.SET_COMENTS,
    payload: fileData,
  };
};

const failureComents = (error) => {
  return {
    type: expedienteConstants.SET_COMENTS_FAILURE,
    payload: error,
  };
};

const requestComents = (error) => {
  return {
    type: expedienteConstants.SET_COMENTS_REQUEST,
    payload: error,
  };
};

export const setComments = (comments) => {
  return async (dispatch) => {
    dispatch(requestComent());
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.publicComents(comments);
      dispatch(successComent(comentData.Body));
    } catch (error) {
      console.log(error); 
      dispatch(failureComent(error.message));
    }
  };
};

export const getComent = (fileComents) => {
  return async (dispatch) => {
    dispatch(requestComents());
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getComents(fileComents);
      dispatch(coments(comentData.Body));
    } catch (error) {
      console.log(error); 
      dispatch(failureComents(error.message));
    }
  };
};

// TRAER DOCUMENTO DE 64 BITS PARA LAS VISTAS PREVIAS
const successDocument64 = (fileData) => {
  return {
    type: expedienteConstants.SET_DOCUMENT64,
    payload: fileData,
  };
};

const successUrlDocument = (urlDocument) => {
  return {
    type: expedienteConstants.GET_URL_DOCUMENT,
    payload: urlDocument,
  };
};

const requestUrlDocument = () => {
  return {
    type: expedienteConstants.REQUEST_URL_DOCUMENT,
  };
};

const failureDocument64 = (error) => {
  return {
    type: expedienteConstants.SET_DOCUMENT64_FAILURE,
    payload: error,
  };
};

const requestDocument64 = () => {
  return {
    type: expedienteConstants.SET_DOCUMENT64_REQUEST,
  };
};

// Descargar documento en base 64
export const getDocument64 = (document_64) => {
  return async (dispatch) => {
    dispatch(requestDocument64());
    // console.log(document_64)
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument(document_64);
      dispatch(successDocument64(comentData.Body));
    } catch (error) {
      console.log(error); 
      dispatch(failureDocument64(error.message));
    }
  };
};

// DESCARGAR DOCUMENTO SIN TOKEN
export const getDownloadDocumentWithOuthT = (document_64) => {
  return async (dispatch) => {
    dispatch(requestDocument64());
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument(document_64);
      dispatch(successDocument64(comentData.Body));
    } catch (error) {
      console.log(error); 
      dispatch(failureDocument64(error.message));
    }
  };
};

export const setUrlDocument64 = (document) => {
  return async (dispatch) => {
    dispatch(requestUrlDocument())
    try {
      dispatch(successUrlDocument(document));
    } catch (error) {
      // dispatch(failureDocument64(error.message));
    }
  };
};

const successVersionsDoc = (fileData) => {
  return {
    type: expedienteConstants.GET_DOCUMENT_VERSIONS_SUCCESS,
    payload: fileData,
  };
};

const failureVersionsDoc = (error) => {
  return {
    type: expedienteConstants.GET_DOCUMENT_VERSIONS_FAILTURE,
    payload: error,
  };
};

const requestVersionsDoc = () => {
  return {
    type: expedienteConstants.GET_DOCUMENT_VERSIONS_REQUEST,
  };
};

export const getDocumentVersions = (idDocument) => {
  return async (dispatch) => {
    dispatch(requestVersionsDoc());
    try {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDocumentVersions(idDocument);
      dispatch(successVersionsDoc(comentData.Body));
    } catch (error) {
      dispatch(failureVersionsDoc(error.message));
    }
  };
};

export const setVersionDocument64 = (document_64) => {
  return async (dispatch) => {
    dispatch(requestDocument64());
    try {
      const fileService = FileService.getInstance();
      const versionDoc = await fileService.getVersionDoc(document_64);
      dispatch(successDocument64(versionDoc.Body));
    } catch (error) {
      dispatch(failureDocument64(error.message));
    }
  };
};


export const getFileUserActionCustom = (filesData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      dispatch(success(filesData));
    } catch (error) {
      console.log(error); 
      dispatch(failure(error.message));
    }
  };
};

export const trueLoading = (data) => {
  return {
    type: expedienteConstants.LOADING_SUCCESS,
    payload: data,
  };
};

export const falseLoading = (data) => {
  return {
    type: expedienteConstants.LOADING_FEATURE,
    payload: data,
  };
};

// ACCIONES DE RESETS

export const resetFile = () => {
  return {
    type: expedienteConstants.RESET_FILE,
  };
};

export const resetDocumentEnlace = () => {
  return {
    type: expedienteConstants.RESET_URL_DOCUMENT_ENLACE,
  };
};

export const resetDocument64 = () => {
  return {
    type: expedienteConstants.RESET_DOCUMENT64,
  };
};

export const resetDocument = () => {
  return {
    type: expedienteConstants.RESET_DOCUMENT,
  };
};

export const resetComents = () => {
  return {
    type: expedienteConstants.RESET_COMENTS,
  };
};


