import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class UsersService {
  static instance;

  constructor() {
    this.baseUrl = "user";
  }

  static getInstance() {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService();
    }
    return UsersService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }


  async GetUsersData(data) {
    try {
      console.log('DATA VERIFICACION', data)
      const response = await GenericService.post({
        endpoint: `${this.baseUrl}/GetUsers`,
        data: Config.setParametersForceEncripted(data, false),
        config: Config.configAuth(),
      });
      console.log("RESPUESTA",data, response)
      const dataUsers = await this.handleResponseForceEncripted(response);
      console.log('dataUsers', dataUsers)
      return dataUsers;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }


  async registerParticipant(data) {
    console.log(data);
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/RegisterUserRole`,
      data: data,
      config: Config.configAuth(),
    });
    console.log(response);
    const roles = await this.handleResponseForceEncripted(response);
    return roles;
  }
  async updateUserRole(data) {
    console.log(data);
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/UpdateUserRole`,
      data: data,
      config: Config.configAuth(),
    });
    console.log(response);
    const roles = await this.handleResponseForceEncripted(response);
    return roles;
  }

  async getRoles(data) {
    const response = await GenericService.post({
      endpoint: `role/GetRoles`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const roles = await this.handleResponseForceEncripted(response);
    return roles;
  }

  async getUserByCorrero(data) {
    const response = await GenericService.post({
      endpoint: `user/GetUserByCorrero`,
      data: Config.setParametersForceEncripted(data, false),
    });
    console.log('GETCORREO', data, response)
    const userDataEmail = await this.handleResponseForceEncripted(response);
    return userDataEmail;
  }

}